import { useActiveSite, useAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import capitalize from 'voca/capitalize';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import Typography from '../Typography/Typography';
import { ResizeContext } from '../../context/ResizeContext';
import store from '../../store/index';

const titleMap = {
  lead: 'Lead Submission',
  event: 'Event',
  visit: 'Page View',
  form: 'Form Submission',
  company: 'Company Matched ',
  chat: 'Chat started',
};

const iconMap = {
  lead: 'flag',
  event: 'flag',
  form: 'flag',
  visit: 'insert_drive_file',
  company: 'business',
  chat: 'comment',
};

function durationFormatted(timeOnPage) {
  return timeOnPage ? new Date(timeOnPage * 1000).toISOString().substr(11, 8) : 'N/A';
}

const Event = ({ data, index }) => {
  const { leadId, organizationId } = useParams();
  const { siteId } = useActiveSite();
  const ref = React.useRef();
  const event = data[index];
  const setSize = ResizeContext.useStoreActions((actions) => actions.setSize);
  const url = store.getState().router.url;
  let timezone = useAuth().user.timezone;

  if (!timezone) {
    timezone = moment.tz.guess();
  }

  useEffect(() => {
    if (ref.current) {
      setSize({ id: index, size: ref.current.getBoundingClientRect().height + 16 });
    }
  }, [ref.current]);

  const eventType = titleMap[event.entity];
  let eventTitle;

  if (event?.entity !== 'event') {
    eventTitle = eventType;
  } else {
    const eventName = event?.event?.category || '';
    eventTitle = isEmpty(eventName) ? eventType : `${eventType}: ${eventName}`;
  }

  return (
    <div
      ref={ref}
      className={classnames({
        'timeline-event': true,
        [`timeline-event-${event.entity}`]: true,
      })}
      data-fx-name="timelineEvent"
    >
      {event.entity === 'session' && (
        <Typography className="session-title" data-fx-name="endOfSessionDuration">
          End of Session | Duration:{' '}
          {event.timeOnPage === 0 ? 'Less than 10 seconds' : durationFormatted(event.timeOnPage)}
        </Typography>
      )}

      {event.entity !== 'session' && (
        <>
          <Typography className="timeline-event-date" data-fx-name="dateTime">
            {moment.tz(event.date, 'Etc/UTC').tz(timezone).format('MMM D, YYYY hh:mma')}
          </Typography>

          <h3 className="timeline-event-title" data-fx-name="title">
            <div data-fx-name="label">{eventTitle}</div>
          </h3>
          {event.entity === 'visit' && (
            <Typography className="timeline-event-url" data-fx-name="url">
              {event?.pageUrl?.full}
            </Typography>
          )}
          {event.entity === 'chat' && <Typography variant="subtitle3">{event.email}</Typography>}
          {(event.source || event.medium) && (
            <div className="timeline-event-source d-flex px-2 py-1 rounded">
              {event.source && (
                <Typography className="mb-0 mr-3" data-fx-name="source">
                  <span className="font-weight-bold">Source:</span> {capitalize(event.source)}
                </Typography>
              )}
              {event.medium && (
                <Typography className="mb-0" data-fx-name="medium">
                  <span className="font-weight-bold">Medium:</span> {capitalize(event.medium)}
                </Typography>
              )}
            </div>
          )}

          {event.entity === 'form' && event.impressionId !== leadId && (
            <Link
              className="timeline-event-link btn btn-sm btn-orange mt-3"
              to={url({
                route: 'mcfxLeadManager',
                siteId,
                entity: `${event.entity}s`,
                leadId: event.impressionId,
              })}
            >
              View Lead
            </Link>
          )}

          {event.entity === 'company' && event.impressionId !== organizationId && (
            <Link
              className="timeline-event-link btn btn-sm btn-primary mt-3"
              to={url({
                route: 'mcfxCompanyTracker',
                siteId,
                organizationId: event?.company?.id,
              })}
            >
              View Company
            </Link>
          )}
          <div className="timeline-event-icon">
            <Icon outlined>{iconMap[event.entity]}</Icon>
          </div>

          {((event.impressionId === leadId && event.entity === 'form') ||
            (event.entity === 'company' && event.impressionId === organizationId)) && (
            <span className="badge badge-primary mt-2">Viewing</span>
          )}
        </>
      )}
    </div>
  );
};

Event.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
};

export default Event;
