import { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import { useAuth } from '@webfx/web-hooks';
import { Avatar, Icon, Html, Button } from '@webfx/core-web';

import styles from './ActivityItem.module.css';

const ActivityItem = ({ item, sectionType }) => {
  const { isGuest } = useAuth();
  const { url } = useStoreState((state) => state.router);
  const [showViewButton, setShowViewButton] = useState(false);

  const viewButtonLabel = {
    messages: 'Message',
    calls: 'Transcript',
  };

  return (
    <div
      className="row justify-content-between align-items-center py-2 px-0 border-bottom container-fluid position-relative bg-hover-blue-00"
      onMouseEnter={() => {
        setShowViewButton(true);
      }}
      onMouseLeave={() => setShowViewButton(false)}
    >
      <>
        {sectionType === 'actionItems' ? (
          <div className="col-md-12" data-fx-name="row">
            <Html str={item.title} className="font-14" data-fx-name="actionItemTitle" />
          </div>
        ) : (
          <>
            <div className="col-md-6" data-fx-name="row">
              <div className="d-flex align-items-center">
                {sectionType === 'messages' ? (
                  <Avatar src={item.creator.avatarUrl} className={styles.avatar} />
                ) : null}
                {sectionType === 'calls' ? (
                  <Icon
                    className="p-2 rounded-circle text-blue-vivid-500 font-22 bg-blue-vivid-100"
                    outlined
                  >
                    phone_in_talk
                  </Icon>
                ) : null}
                <div className="d-flex flex-column ml-2">
                  {['messages', 'calls'].includes(sectionType) ? (
                    <span className="text-gray-500 font-13 bold">{`${item.creator.firstName} ${item.creator.lastName}`}</span>
                  ) : null}
                  {sectionType !== 'actionItems' ? (
                    <span
                      className={classnames('text-gray-700 font-14', styles.title)}
                      data-fx-name="messageTitle"
                    >
                      {item.title}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <span className="text-gray-500 font-12" data-fx-name="dateTime">
                {['messages', 'calls'].includes(sectionType)
                  ? DateTime.fromISO(item.updatedAt).toFormat('ccc, LLL dd, yyyy | h:mma')
                  : null}
              </span>
            </div>
          </>
        )}
        {!isGuest && showViewButton && sectionType !== 'actionItems' ? (
          <Button
            text={`View ${viewButtonLabel[sectionType]}`}
            icon="arrow_forward"
            iconPosition="right"
            iconTrailing
            to={url({ route: 'messages', messageId: item?.messageId })}
            className={classnames(
              'position-absolute bg-blue-vivid-300 font-13 text-white semibold',
              styles.actionButton
            )}
          />
        ) : null}
      </>
    </div>
  );
};
export default ActivityItem;
