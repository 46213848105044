import { useAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import { useStoreActions } from 'easy-peasy';
import React, { useEffect } from 'react';
import { If, Then } from 'react-if';
import { Link, useRouteMatch } from 'react-router-dom';
import { ResizeContext } from '../../context';
import store from '../../store';
import Badge from '../Badge/Badge';

export const DropdownItem = React.forwardRef(
  ({ href, children, className, style, data, index, ...passThroughProps }, ref) => {
    const setActiveSiteId = useStoreActions((actions) => actions.sites.setActive);
    const { url } = store.getState().router;
    const match = useRouteMatch();

    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    const isAdmin = useAuth().isAdmin;
    const setSize = ResizeContext.useStoreActions((actions) => actions.setSize);

    let { path } = match;
    const idIndex = path.indexOf('/:id');

    // cut off any singular url routes
    // as ids will differ per assoc.
    if (-1 !== idIndex) {
      path = path.slice(0, idIndex);
    }

    href = href || url({ route: path, siteId: data.siteId });

    useEffect(() => {
      if (resolvedRef.current) {
        setSize({
          id: index,
          size: resolvedRef.current.getBoundingClientRect().height,
        });
      }
    }, [index, resolvedRef, setSize]);

    // Update store state immediately to avoid race conditions
    const onClick = () => {
      setActiveSiteId(data.siteId);
    };

    let variant = 'light';
    if (data.status === 'Active') {
      variant = 'success';
    } else if (data.status === 'Prospect') {
      variant = 'primary';
    }

    return (
      <div onClick={onClick}>
        <Link
          to={href}
          {...passThroughProps}
          role="button"
          key={`site-${data.siteId}`}
          ref={resolvedRef}
          style={style}
          className={`dropdown-item react-window-item site-select-item site-select-item-${data.siteId} ${className}`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className={classnames(['site-group', { 'w-100': !isAdmin }])}>
              <h4 className="site-select-name">{children}</h4>
              <p className="site-select-url">{data.url.replace(/^http(s)?:\/\/(www\.)?/, '')}</p>
            </div>
            <If condition={isAdmin}>
              <Then>
                <Badge variant={variant} pill>
                  {data.status}
                </Badge>
              </Then>
            </If>
          </div>
        </Link>
      </div>
    );
  }
);
