import React, { useContext } from 'react';
import { Accordion as ReactAccordion, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';

import styles from './Accordion.module.css';
import _ from 'lodash';

const ContextAwareToggle = ({
  headerClassName,
  iconOutlined,
  iconClassName,
  icon,
  titleClassName,
  title,
  subtitle,
  subtitleClassName,
  titleFill,
  headerComponent,
  withCollapseText,
  eventKey,
  callback,
  tooltipData,
}) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = currentEventKey === eventKey;

  const renderTooltip = (props) => (
    <Tooltip {...props} className={`${!tooltipData && 'd-none'}`} bsPrefix={styles.tooltip}>
      {tooltipData}
    </Tooltip>
  );

  return (
    <Card.Header
      className={classnames(['d-flex align-items-center', styles.header, headerClassName])}
      onClick={decoratedOnClick}
    >
      <Icon outlined={iconOutlined} className={classnames(['mr-3', styles.icon, iconClassName])}>
        {icon}
      </Icon>
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        <div className={classnames({ 'flex-fill': titleFill })}>
          <Typography
            variant={'h5'}
            bold={true}
            className={classnames(titleClassName)}
            data-fx-name="cardTitle"
          >
            {title}
          </Typography>
          {subtitle ? (
            <Typography
              variant={'subtitle1'}
              className={classnames([styles.subtitle, subtitleClassName])}
            >
              {subtitle}
            </Typography>
          ) : null}
        </div>
      </OverlayTrigger>
      {headerComponent}

      <button
        type='button'
        className={classnames([
          'd-flex justify-content-end align-items-center ml-2',
          styles.toggle,
        ])}
        type="button"
      >
        {isCurrentEventKey ? (
          <>
            {withCollapseText && <small className="mr-1">Collapse</small>}
            <Icon data-fx-name="collapseButton">keyboard_arrow_up</Icon>
          </>
        ) : (
          <>
            {withCollapseText && <small className="mr-1">Expand</small>}
            <Icon data-fx-name="uncollapseButton">keyboard_arrow_down</Icon>
          </>
        )}
      </button>
    </Card.Header>
  );
};

const Accordion = ({
  title = '',
  subtitle,
  icon = 'priority_high',
  iconOutlined = false,
  className,
  containerClassName,
  containerStyles,
  headerClassName,
  bodyClassName,
  iconClassName,
  titleClassName,
  titleFill = true,
  subtitleClassName,
  collapsed = false,
  collapsedKey = '0', // this forces accordion to not ignore clicks
  onSelect,
  activeKey = 0,
  headerComponent = null,
  withCollapseText = false, // adds wording to collapse button
  children,
  cardClassName, // TODO: deprecate
  cardBodyClassName, // TODO: deprecate
  headerIcon, // TODO: deprecate
  headerTitle = '', // TODO: deprecate
  headerIconOutlined, // TODO: deprecate
  headerSubTitle, // TODO: deprecate
  tooltipData,
}) => {
  containerClassName = cardClassName ? cardClassName : containerClassName;
  bodyClassName = cardBodyClassName ? cardBodyClassName : bodyClassName;
  icon = headerIcon ? headerIcon : icon;
  iconOutlined = headerIconOutlined ? headerIconOutlined : iconOutlined;
  title = headerTitle ? headerTitle : title;
  subtitle = headerSubTitle ? headerSubTitle : subtitle;

  let defaultKey = !collapsed ? '0' : '';
  if (typeof activeKey !== 'number') {
    defaultKey = activeKey;
  }

  return (
    <ReactAccordion
      className={classnames(className)}
      onSelect={onSelect}
      defaultActiveKey={defaultKey}
    >
      <Card
        data-fx-name={_.camelCase(title)}
        className={classnames(containerClassName)}
        style={containerStyles}
      >
        <ContextAwareToggle
          eventKey={collapsed ? collapsedKey : defaultKey}
          icon={icon}
          iconOutlined={iconOutlined}
          title={title}
          titleFill={titleFill}
          subtitle={subtitle}
          withCollapseText={withCollapseText}
          headerComponent={headerComponent}
          headerClassName={headerClassName}
          iconClassName={iconClassName}
          titleClassName={titleClassName}
          subtitleClassName={subtitleClassName}
          tooltipData={tooltipData}
        />

        <ReactAccordion.Collapse
          eventKey={collapsed ? collapsedKey : defaultKey}
          data-fx-name="cardBody"
        >
          <Card.Body className={classnames(bodyClassName)}>{children}</Card.Body>
        </ReactAccordion.Collapse>
      </Card>
    </ReactAccordion>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  iconOutlined: PropTypes.bool,
  headerComponent: PropTypes.node,
  collapsed: PropTypes.bool,
};

export default Accordion;
