import isEmpty from 'lodash/isEmpty';

import { ResizeContext } from '../../context/ResizeContext';
import Spinner from '../Spinner/Spinner';
import EventList from './EventList';

import './Timeline.css';

const Timeline = ({ className, data = null, height = 200 }) => {
  return (
    <div className={`timeline ${className}`}>
      <ResizeContext.Provider>
        {!data && <Spinner animation="border" size="sm" className="rv-spinner" />}
        {!isEmpty(data?.events) && (
          <EventList events={data.events} height={height} sid={data.sid} />
        )}
      </ResizeContext.Provider>
    </div>
  );
};

export default Timeline;
