import { api } from '@webfx/core-web';

const useHistory = (companyId) => {
  return api.useQuery(
    [
      'tw/history',
      {
        companyId,
        $sort: {
          date: -1,
          createdAt: -1,
        },
        $limit: 30, // Hack to get the latest 30 items that we will filter messages out of. Next step will be to add that query param to the API
      },
    ],
    {
      enabled: !!companyId,
      select: (data) => data || [],
    }
  );
};

export default useHistory;
