import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList as List } from 'react-window';
import { ResizeContext } from '../../context/ResizeContext';
import Event from './Event';

function EventList({ events, height }) {
  const listRef = useRef();
  const setRef = ResizeContext.useStoreActions((actions) => actions.setRef);
  const getSize = ResizeContext.useStoreState((state) => state.size);

  useEffect(() => {
    return () => {
      setRef(listRef);
    };
  }, [listRef.current]);

  const Row = ({ style, ...passThroughProps }) => (
    <div className="react-window-item" style={style}>
      <Event {...passThroughProps} />
    </div>
  );

  return (
    <List
      ref={listRef}
      height={height}
      itemSize={getSize}
      itemCount={events?.length || 0}
      width="100%"
      itemData={events}
      estimatedItemSize={30}
    >
      {Row}
    </List>
  );
}

EventList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  height: PropTypes.number.isRequired,
};

export default EventList;
