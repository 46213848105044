import React from 'react';
import { createContextStore } from 'easy-peasy';

export const BulkSelectionContext = createContextStore({});

const BulkSelectionProvider = ({ children }) => {
  return <BulkSelectionContext.Provider>{children}</BulkSelectionContext.Provider>;
};

export default BulkSelectionProvider;
