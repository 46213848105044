import { useAuth } from '@webfx/web-hooks';
import { useStoreState } from 'easy-peasy';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { Case, Default, Switch } from 'react-if';
import InfiniteScroll from 'react-infinite-scroll-component';
import { VariableSizeList as List } from 'react-window';
import { useDebounce } from 'use-debounce';
import { ResizeContext } from '../../context';
import useSitesQuery from '../../hooks/queries/useSitesQuery';
import Input from '../Input/Input';
import { DropdownItem } from './DropdownItem';

const APP_TO_ROLE = {
  marketingcloud: 'mcfx',
  nutshell: 'nutshell',
  chat: 'chatfx',
  competitorspy: 'mcfx',
  playbook: 'playbookfx',
};

export const Menu = React.forwardRef(({ style, className, onSearch, forRole }, ref) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const app = useStoreState((state) => state.router.app);

  const isAdmin = useAuth().isAdmin;
  const listRef = useRef();
  const listOuterRef = useRef();
  const setRef = ResizeContext.useStoreActions((actions) => actions.setRef);
  const getSize = ResizeContext.useStoreState((state) => state.size);

  useEffect(() => {
    setRef(listRef);
  }, []);

  const roleToFilter = useMemo(() => {
    if (forRole) {
      return forRole;
    }

    if (APP_TO_ROLE[app]) {
      return APP_TO_ROLE[app];
    }

    return null;
  }, [app]);

  // Add ID to react-window inner component to be able to use InfiniteScroll on it.
  useEffect(() => {
    if (!listOuterRef.current) {
      return;
    }

    listOuterRef.current.id = 'select-list-wrapper';
  }, [listOuterRef.current]);

  const params = useMemo(() => {
    const params = {
      $select: ['siteId', 'name', 'url'],
      $join: { status: true },
      $limit: 100,
      $sort: { name: 1 },
      role: roleToFilter,
      status: {
        $in: ['Active', 'Prospect'],
      },
    };
    if (debouncedSearch) {
      params.name = { $like: `%${debouncedSearch}%` };
    }

    return params;
  }, [debouncedSearch]);

  const sitesQuery = useSitesQuery(params);
  const RowItem = React.forwardRef(({ data, index, style }, ref) => {
    return (
      <Dropdown.Item
        ref={ref}
        eventKey={data[index]}
        key={data[index].siteId}
        index={index}
        as={DropdownItem}
        data={data[index]}
        style={style}
      >
        {data[index].name}
      </Dropdown.Item>
    );
  });

  return (
    <div ref={ref} style={style} className={className}>
      <div className="select-menu-search">
        <Input
          className="mb-0"
          variant="sm"
          icon="search"
          iconPosition="left"
          placeholder={isAdmin ? 'Search for a Client' : 'Search for a Profile'}
          onChange={(e) => {
            setSearch(e.target.value);
            if (onSearch) {
              onSearch(e);
            }
          }}
          value={search}
          clearable={true}
          onClear={() => {
            setSearch('');
          }}
          autoFocus
        />
      </div>

      <Switch>
        <Case condition={sitesQuery.isLoading}>
          <div className="text-center p-4">
            <Spinner animation="border" />
          </div>
        </Case>
        <Case condition={!sitesQuery?.data?.data.length}>
          <div className="text-center p-4">No sites found</div>
        </Case>
        <Default>
          <List
            className="select-menu-items"
            outerRef={listOuterRef}
            innerElementType={({ children }) => (
              <InfiniteScroll
                scrollableTarget="select-list-wrapper"
                next={sitesQuery.fetchNextPage}
                hasMore={sitesQuery.hasNextPage}
                loader={<Spinner />}
                dataLength={sitesQuery.data.total}
              >
                {children}
              </InfiniteScroll>
            )}
            ref={listRef}
            height={800}
            itemSize={getSize}
            itemCount={sitesQuery?.data?.data.length}
            width="100%"
            itemData={sitesQuery?.data?.data}
            estimatedItemSize={60}
          >
            {RowItem}
          </List>
        </Default>
      </Switch>
    </div>
  );
});
