import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useDebugValue, useEffect, useMemo, useRef, useState } from 'react';

import { startCase } from 'lodash';
import { matchSorter } from 'match-sorter';
import { Dropdown } from 'react-bootstrap';
import { VariableSizeList as List } from 'react-window';
import { MenuContext, withMenuContext } from '../../context/MenuContext';
import { ResizeContext } from '../../context/ResizeContext';
import Icon from '../Icon/Icon';
import Input from '../Input/Input';

import Spinner from '../Spinner/Spinner';
import './SelectMenu.style.css';
export const Toggle = React.forwardRef(({ children, ...passThroughProps }, ref) => (
  <button
    ref={ref}
    type="button"
    {...passThroughProps}
    className="select-menu-toggle"
    data-fx-name="dropdownButton"
  >
    {children}
    <Icon className="text-gray-400 font-24 ml-2">keyboard_arrow_down</Icon>
  </button>
));

export const DropdownItem = React.forwardRef(({ children, index }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  const setSize = ResizeContext.useStoreActions((actions) => actions.setSize);

  useEffect(() => {
    if (resolvedRef.current) {
      setSize({
        id: index,
        size: resolvedRef.current.getBoundingClientRect().height,
      });
    }
  }, [defaultRef.current]);

  return <div ref={resolvedRef}>{children}</div>;
});

const Menu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      'aria-labelledby': labeledBy,
      searchable,
      onSearch,
      searchPlaceholder,
      estimatedItemSize = 60,
      menuWidth,
    },
    ref
  ) => {
    const items = React.Children.toArray(children);
    const value = MenuContext.useStoreState((state) => state.search);
    const setValue = MenuContext.useStoreActions((actions) => actions.setSearch);
    const setRef = ResizeContext.useStoreActions((actions) => actions.setRef);
    const listRef = useRef();
    const getSize = ResizeContext.useStoreState((state) => state.size);

    useEffect(() => {
      setRef(listRef);
    }, [setRef, listRef]);

    const RowItem = ({ data, index, style }) => {
      return (
        <div className="react-window-item" style={style} data-fx-name="windowItems">
          <DropdownItem index={index} style={style}>
            {data[index]}
          </DropdownItem>
        </div>
      );
    };

    return (
      <div
        ref={ref}
        style={{ ...style, width: menuWidth }}
        className={className}
        aria-labelledby={labeledBy}
        data-fx-name="dropdownWindow"
      >
        {searchable && (
          <div className="select-menu-search">
            <Input
              className="mb-0"
              variant="sm"
              icon="search"
              iconPosition="left"
              placeholder={searchPlaceholder}
              onChange={(e) => {
                setValue(e.target.value);
                if (onSearch) {
                  onSearch(e);
                }
              }}
              value={value}
              clearable={true}
              onClear={(e) => {
                setValue('');
                e.preventDefault(); // prevent submitting when inside a form
              }}
              autoFocus
            />
          </div>
        )}
        <div className="select-menu-items" data-fx-name="itemList">
          <List
            ref={listRef}
            height={234}
            itemSize={getSize}
            itemCount={items.length}
            width="100%"
            itemData={items}
            estimatedItemSize={estimatedItemSize}
          >
            {RowItem}
          </List>
        </div>
      </div>
    );
  }
);

const propTypes = {
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  items: PropTypes.array,
  itemLabel: PropTypes.string,
  itemValue: PropTypes.string,
  name: PropTypes.string,
  searchable: PropTypes.bool,
  onSearch: PropTypes.func,
  // searchProp: PropTypes.string,
  maxLabelLength: PropTypes.number,
  itemComponent: PropTypes.elementType,
  className: PropTypes.string,
  value: PropTypes.any,
  dataFxName: PropTypes.string,
};

const SelectMenu = ({
  placeholder = 'Switch to a Different Project',
  onSelect,
  options = [],
  optionLabel = 'label',
  optionValue = 'value',
  name,
  ['data-fx-name']: dataFxName,
  async = false,
  noResultsText = '',
  loading = false,
  searchable = true,
  onSearch = null,
  clearable = false,
  onClear,
  searchProp = 'label',
  searchPlaceholder = 'Type to filter...',
  maxLabelLength = 27,
  itemComponent,
  defaultValue = null,
  className,
  fullWidth,
  value,
  toggle: CustomToggle,
  showValueOnSelected = true,
  style,
  onDelete = null,
  menuWidth = '100%',
}) => {
  let [activeOption, setActiveOption] = useState(defaultValue);

  useDebugValue(activeOption);

  const [label, setLabel] = useState(placeholder);
  const searchValue = MenuContext.useStoreState((state) => state.search);

  // Allow for menu to be controlled
  if (value) {
    activeOption = value;
    setActiveOption = () => {};
  }

  const doOnSelect = (eventKey, event) => {
    setActiveOption(eventKey);
    if (onSelect) {
      onSelect(eventKey, event);
    }
  };

  // setup label
  useEffect(() => {
    const activeItem = options.find((o) => o[optionValue] == activeOption) || {
      [optionValue]: '',
      [optionLabel]: '',
    };
    let l = activeOption && options.length ? activeItem[optionLabel] : placeholder;

    if (activeOption && l?.length > maxLabelLength) {
      l = l.slice(0, maxLabelLength) + '...';
    }

    if (showValueOnSelected) {
      setLabel(l);
    }
  }, [activeOption, placeholder, options.length]);

  // filter options off search
  if (!Array.isArray(searchProp)) {
    searchProp = [searchProp];
  }

  const filteredOptions = useMemo(() => {
    return searchValue && searchValue !== '' && !async
      ? matchSorter(options, searchValue, { keys: searchProp })
      : options;
  }, [options, searchValue]);

  return (
    <Dropdown
      className={classNames('select-menu', {
        [className]: className,
        'select-menu-full-width': fullWidth,
      })}
      style={{ style }}
      data-fx-name={dataFxName}
    >
      <Dropdown.Toggle as={CustomToggle ?? Toggle}>
        {name && <span className="flex-grow-1 text-left">{name}</span>}
        <span className={name && 'text-gray-600 ml-2'} data-fx-name="active">
          {label}
        </span>
      </Dropdown.Toggle>
      {clearable && activeOption && (
        <button className="select-menu-clear" onClick={onClear}>
          <Icon>close</Icon>
        </button>
      )}
      <ResizeContext.Provider>
        <Dropdown.Menu
          as={Menu}
          alignRight={true}
          className="select-menu-menu"
          searchable={searchable}
          onSearch={onSearch}
          searchProp={searchProp}
          searchPlaceholder={searchPlaceholder}
          menuWidth={menuWidth}
          data-fx-name="selectMenuContainer"
        >
          {loading && <Spinner className={'mt-2'} />}
          {!loading && filteredOptions.length === 0 && (
            <div className="text-gray-700 ml-2 pt-2 font-13">{noResultsText}</div>
          )}
          {filteredOptions.map((option, index) => {
            const iconPosition = option?.iconPosition || 'left';

            return (
              <Dropdown.Item
                key={index}
                as={itemComponent}
                eventKey={option[optionValue]}
                onSelect={doOnSelect}
                data={option}
                active={activeOption === option[optionValue]}
                index={index}
                href={option.href}
                className={classNames(
                  option?.itemClassName &&
                    `${option.itemClassName} d-flex justify-content-start align-items-center`
                )}
                data-fx-name={`option${startCase(option[optionValue]).replaceAll(/\s+/g, '')}`}
              >
                {option?.icon && (
                  <>
                    {iconPosition === 'right' && option[optionLabel]}

                    {option?.iconHasDeleteEvent ? (
                      <a
                        href={'#'}
                        onClick={(e) => {
                          if (onDelete) {
                            e.stopPropagation(); // prevent parent selection effect
                            onDelete(option);
                          }
                        }}
                        data-fx-name="deleteItemButton"
                      >
                        <Icon className={option?.iconClassName ? option.iconClassName : ''}>
                          {option.icon}
                        </Icon>
                      </a>
                    ) : (
                      <Icon className={option?.iconClassName ? option.iconClassName : ''}>
                        {option.icon}
                      </Icon>
                    )}

                    {iconPosition === 'left' && option[optionLabel]}
                  </>
                )}

                {!option?.icon && option[optionLabel]}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </ResizeContext.Provider>
    </Dropdown>
  );
};

SelectMenu.propTypes = propTypes;

export default withMenuContext(SelectMenu);
