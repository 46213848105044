import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';

import ErrorPage from '../ErrorPage/ErrorPage';

import './NotFoundPage.styles.css';

const NotFoundPage = () => {
  const { url } = useStoreState((state) => state.router);

  const body = (
    <ErrorPage title="404" subtitle="This page does not exist.">
      <div>
        <p>
          Please return to the <Link to={url({ route: 'dashboard' })}>Dashboard</Link>
        </p>
      </div>

      <div className="mt-auto pb-5">
        <hr className="mt-5 mb-3 p-0 mx-0" style={{ width: '50px', borderColor: '#000' }} />
        <p>
          Still can’t find what you’re looking for?
          <br />
          Email our support desk at <a href="mailto:app@webfx.com">app@webfx.com</a>.
        </p>
      </div>
    </ErrorPage>
  );

  return body;
};

export default NotFoundPage;
