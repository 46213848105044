import React, { useEffect, useState } from 'react';
import { Icon, Spinner } from '@webfx/core-web';
import {
  useAuth,
  useBanner,
  useCreateNutshellInstance,
  useFlags,
  useSitePlan,
  useActiveSite,
} from '@webfx/web-hooks';
import { NutshellEmbedFrame, NutshellLoading } from '@webfx/web-ui-connected';
import classnames from 'classnames';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Container from '@webfx/schedulerfx-web/src/ui/components/Container';
const Schedules = React.lazy(() => import('@webfx/schedulerfx-web/src/ui/pages/Schedules'));

import canAccessSchedulerCheck from '../../../../../../scheduler-web/src/utils/canAccessSchedulerCheck';

// import images
import getStartedOne from '../../../../assets/images/scheduler/get-started-1.png';
import getStartedTwo from '../../../../assets/images/scheduler/get-started-2.png';
import getStartedThree from '../../../../assets/images/scheduler/get-started-3.png';
import bookBg from '../../../../assets/images/scheduler/landing-page-frame-1.png';
import leadManagerPreview from '../../../../assets/images/scheduler/lead-manager-preview.png';
import topFeaturesBg from '../../../../assets/images/scheduler/top-features-bg.png';

import useMenuState from '../../../components/Menu/useMenuState';

import footerBg from '../../../../assets/images/scheduler/footer-bg.png';
import BannerModal from '../components/BannerModal';
import LandingPageNutshell from '../LandingPageNutshell';

import style from './LandingPage.module.css';

const Stats = ({ meetWithMeSlug, setShowSchedulerModal, showSchedulerModal }) => {
  const { url } = useMenuState();

  return (
    <>
      <div className="row">
        <div className="col-5">
          <div className="row">
            <div className="col-2" style={{ paddingTop: '34px' }}>
              <svg
                width="28"
                height="30"
                viewBox="0 0 28 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M24.6666 3.00001H23.3333V0.333344H20.6666V3.00001H7.33329V0.333344H4.66663V3.00001H3.33329C1.86663 3.00001 0.666626 4.20001 0.666626 5.66668V27C0.666626 28.4667 1.86663 29.6667 3.33329 29.6667H24.6666C26.1333 29.6667 27.3333 28.4667 27.3333 27V5.66668C27.3333 4.20001 26.1333 3.00001 24.6666 3.00001ZM24.6666 27H3.33329V12.3333H24.6666V27ZM3.33329 9.66668H24.6666V5.66668H3.33329V9.66668Z"
                  fill="#207DE9"
                />
              </svg>
            </div>

            <div className="col pl-4">
              <div className="row font-weight-bold" style={{ fontSize: '35px' }}>
                Coming Soon
              </div>
              <div className="row" style={{ fontSize: '22px' }}>
                Meetings booked
              </div>
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="row">
            <div className="col-2" style={{ paddingTop: '34px' }}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6667 13.3333C16.6667 11.1333 14.8667 9.33333 12.6667 9.33333C10.4667 9.33333 8.66665 11.1333 8.66665 13.3333C8.66665 15.5333 10.4667 17.3333 12.6667 17.3333C14.8667 17.3333 16.6667 15.5333 16.6667 13.3333ZM12.6667 14.6667C11.9333 14.6667 11.3333 14.0667 11.3333 13.3333C11.3333 12.6 11.9333 12 12.6667 12C13.4 12 14 12.6 14 13.3333C14 14.0667 13.4 14.6667 12.6667 14.6667ZM21.3333 17.3333C22.8133 17.3333 24 16.1467 24 14.6667C24 13.1867 22.8133 12 21.3333 12C19.8533 12 18.6533 13.1867 18.6667 14.6667C18.6667 16.1467 19.8533 17.3333 21.3333 17.3333ZM15.9867 2.67999C8.62665 2.67999 2.65332 8.65333 2.65332 16.0133C2.65332 23.3733 8.62665 29.3467 15.9867 29.3467C23.3467 29.3467 29.32 23.3733 29.32 16.0133C29.32 8.65333 23.3467 2.67999 15.9867 2.67999ZM7.78665 22.8267C8.69332 22.1067 10.8133 21.3467 12.6667 21.3467C12.76 21.3467 12.8667 21.36 12.9733 21.36C13.2933 20.5067 13.8667 19.64 14.7067 18.88C13.96 18.7467 13.2533 18.6667 12.6667 18.6667C10.9333 18.6667 8.14665 19.2667 6.35999 20.5733C5.69332 19.1867 5.31999 17.64 5.31999 16C5.31999 10.12 10.1067 5.33333 15.9867 5.33333C21.8667 5.33333 26.6533 10.12 26.6533 16C26.6533 17.6 26.2933 19.12 25.6533 20.4933C24.32 19.7067 22.5067 19.3333 21.3333 19.3333C19.3067 19.3333 15.3333 20.4133 15.3333 22.9333V26.64C12.3067 26.4667 9.61332 25.0267 7.78665 22.8267Z"
                  fill="#207DE9"
                />
              </svg>
            </div>

            <div className="col pl-4">
              <div className="row font-weight-bold" style={{ fontSize: '35px' }}>
                Coming Soon
              </div>
              <div className="row" style={{ fontSize: '22px' }}>
                Accounts Connected
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row  mt-5">
        <div className="col-5">
          <button
            onClick={() => setShowSchedulerModal(!showSchedulerModal)}
            style={{ height: '62px' }}
            type="button"
            className="btn btn-primary font-24 mr-5 text-capitalize"
          >
            <span>View Meeting Types&nbsp;</span>
            <span>
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.00001 0.833344L4.17751 1.65584L7.43251 4.91668H0.333344V6.08334H7.43251L4.17751 9.34418L5.00001 10.1667L9.66668 5.50001L5.00001 0.833344Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
        </div>

        <div className="col-5">
          {meetWithMeSlug && (
            <a
              style={{ height: '62px', paddingTop: '12px' }}
              type="button"
              className="btn btn-outline-primary font-24 text-capitalize"
              href={url({ route: 'schedulerMeeting', id: meetWithMeSlug })}
              target="_blank"
              rel="noreferrer"
            >
              View ‘Meet with Me’ page
            </a>
          )}
        </div>
      </div>
    </>
  );
};

const GetStarted = ({ setShowSchedulerModal, showSchedulerModal, canViewScheduler }) => {
  const { siteId } = useActiveSite();

  return (
    <div className="row">
      {canViewScheduler && (
        <button
          onClick={() => setShowSchedulerModal(!showSchedulerModal)}
          style={{ width: '220px' }}
          type="button"
          className="btn btn-success font-24 mr-5 text-capitalize"
        >
          <span>Set Up Now!&nbsp;</span>
          <span>
            <svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.00001 0.833344L4.17751 1.65584L7.43251 4.91668H0.333344V6.08334H7.43251L4.17751 9.34418L5.00001 10.1667L9.66668 5.50001L5.00001 0.833344Z"
                fill="white"
              />
            </svg>
          </span>
        </button>
      )}

      {!canViewScheduler && (
        <a
          href={`/marketingcloudfx/${siteId}/call-to-action`}
          className="btn btn-success font-24 mr-5 text-capitalize"
        >
          <span>Upgrade Now!&nbsp;</span>
          <span>
            <svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.00001 0.833344L4.17751 1.65584L7.43251 4.91668H0.333344V6.08334H7.43251L4.17751 9.34418L5.00001 10.1667L9.66668 5.50001L5.00001 0.833344Z"
                fill="white"
              />
            </svg>
          </span>
        </a>
      )}
      <a
        target="_blank"
        href="/help-center/z311jb3li4-scheduler-fx"
        style={{ width: '180px' }}
        type="button"
        className="btn btn-outline-primary font-24 text-capitalize"
        rel="noreferrer"
      >
        learn more
      </a>
    </div>
  );
};
/**
 *
 */
const LandingPage = () => {
  const [showBannerModal, setShowBannerModal] = useState(false);

  const { url, siteId } = useMenuState();
  const user = useAuth().user;

  const sitePlan = useSitePlan(true);
  const canViewScheduler = canAccessSchedulerCheck(sitePlan, user);
  const flags = useFlags();

  const { onGetMeetWithMeSlug } = useStoreActions((actions) => actions.scheduler);
  const meetWithMeSlug = useStoreState((state) => state.scheduler.meetWithMeSlug);

  const [showSchedulerModal, setShowSchedulerModal] = useState(false);
  const toggleShowSchedulerModal = () => setShowSchedulerModal((state) => !state);
  const activeRoute = useStoreState((s) => s.router.active);

  const nutshell = useCreateNutshellInstance();

  const handlePageWrapperBlur = (state, setState = null) => {
    const landingPageWrapper = document.getElementById('landingPageWrapper');

    if (state && landingPageWrapper) {
      landingPageWrapper.classList.add('landingPageBlur');
    }
    if (!state && landingPageWrapper) {
      landingPageWrapper.classList.remove('landingPageBlur');
    }

    if (setState) {
      setState(state);
    }
  };

  useBanner({
    route: activeRoute,
    logo: 'SchedulerFX',
    copy: {
      TitleComponent: () => (
        <span>
          Book <span className="text-success">10% </span>more appointments and sales meetings with
          our integrated calendar scheduling system
        </span>
      ),
      onButtonClick: () => {
        handlePageWrapperBlur(true, setShowBannerModal);
      },
    },
  });

  const schedulerfxUsers = sitePlan?.calendars ?? 0 === -1;
  useEffect(() => {
    setShowSchedulerModal(false);
    if (siteId && user?.userId) {
      onGetMeetWithMeSlug(user.userId);
    }
  }, [siteId, user.userId]);

  if (nutshell.isLoading) {
    return <Spinner />;
  }

  if (flags.schedulerfxNutshellscheduler && !nutshell?.data?.nsInstanceId) {
    return <LandingPageNutshell />;
  }

  if (flags.schedulerfxNutshellscheduler && nutshell?.data?.nsInstanceId) {
    return (
      <NutshellEmbedFrame
        embedURL="https://nutshell.webfx.com/embed/scheduler"
        loadingComponent={<NutshellLoading />}
      />
    );
  }

  return (
    <div id="landingPageWrapper">
      <Container style={{ maxWidth: '1100px' }}>
        <>
          {showSchedulerModal === true && canViewScheduler && (
            <Schedules toggleShowSchedulerModal={toggleShowSchedulerModal} />
          )}
          <div
            data-fx-name="schedulerLandingPage"
            className="bg-white container"
            style={{ maxWidth: '1100px' }}
          >
            <div
              className="row"
              style={{
                backgroundImage: `url(${bookBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                paddingTop: '95px',
                paddingLeft: '114px',
                backgroundSize: '1320px',
              }}
            >
              <div className="container">
                <div className="row mb-4">
                  <span style={{ fontSize: '50px', fontWeight: '500', color: '#000000' }}>
                    Scheduler
                  </span>
                  <span className="mt-1 ml-2">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 95 71"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M95 0H0.352112V71H95V0Z" fill="#4C74B9" />
                      <path
                        d="M11.2045 10.925V61.3204H23.5694V41.6387H40.3285V30.6067H23.5694V21.8712H44.2322L37.8351 10.925H11.2045Z"
                        fill="white"
                      />
                      <path
                        d="M87.2332 61.3204H72.9472L57.5371 36.1227L72.3749 10.925H87.2332L72.3749 36.1227L87.2332 61.3204Z"
                        fill="#BDCFDC"
                      />
                      <path
                        d="M42.6791 10.925H56.9448L72.3755 36.1227L57.5375 61.3204H42.6791L57.5375 36.1227L42.6791 10.925Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
                <div className="row mb-3">
                  <div style={{ width: '600px' }}>
                    <span
                      style={{
                        fontSize: '50px',
                        fontWeight: '900',
                        color: '#000000',

                        lineHeight: 'initial',
                      }}
                    >
                      Book Calls Faster, Close Sales Faster and Move Past Your Competitors.
                    </span>
                  </div>
                </div>
                <div className="row mb-4">
                  <div style={{ width: '550px' }}>
                    <span className="font-16" style={{ color: '#869298' }}>
                      Provide a better call scheduling experience for your prospects with
                      SchedulerFX by allowing them to easily select a time on your (or your teams)
                      calendar that works best for their schedules.
                    </span>
                    <br />
                    <span className="font-16" style={{ color: '#869298' }}>
                      Set working hours, send your booking link, and eliminate back and forth when
                      booking appointments
                    </span>
                  </div>
                </div>
                {canViewScheduler && meetWithMeSlug && (
                  <Stats
                    url={url}
                    showSchedulerModal={showSchedulerModal}
                    meetWithMeSlug={meetWithMeSlug}
                    setShowSchedulerModal={setShowSchedulerModal}
                  />
                )}
                <div className="mb-3">
                  {!meetWithMeSlug && (
                    <GetStarted
                      showSchedulerModal={showSchedulerModal}
                      meetWithMeSlug={meetWithMeSlug}
                      setShowSchedulerModal={setShowSchedulerModal}
                      canViewScheduler={canViewScheduler}
                    />
                  )}
                </div>
                {sitePlan?.name && (
                  <div className="row align-items-center">
                    <div className="font-14 semibold mr-2">
                      Current Plan: <span className="font-weight-bold">{sitePlan?.name}</span>
                    </div>
                    <div className="font-13 text-gray-700">
                      ({schedulerfxUsers === -1 ? 'unlimited' : schedulerfxUsers} calendar
                      connections)
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="row mt-5"
              style={{
                paddingLeft: '95px',
                backgroundImage: `url(${topFeaturesBg})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <div className="col">
                <div style={{ marginTop: '50px' }}>
                  <h3 className="text-capitalize" style={{ fontWeight: '900' }}>
                    Book 10% More Appointments & Sales Meetings
                  </h3>

                  <div className="d-flex align-items-center font-16 mb-3">
                    <Icon className="mr-2 font-18" style={{ color: '#41D58C' }}>
                      check_circle
                    </Icon>
                    Embed the scheduling system on landing pages
                  </div>
                  <div className="d-flex align-items-center font-16 mb-3">
                    <Icon className="mr-2 font-18" style={{ color: '#41D58C' }}>
                      check_circle
                    </Icon>
                    Track demo requests as LeadManagerFX leads
                  </div>
                  <div className="d-flex align-items-center font-16 mb-3">
                    <Icon className="mr-2 font-18" style={{ color: '#41D58C' }}>
                      check_circle
                    </Icon>
                    Integrate with Gmail & Outlook calendars
                  </div>
                </div>
              </div>
              <div className="col">
                <img height="400" src={leadManagerPreview} />
              </div>
            </div>
            <div className="row" style={{ paddingLeft: '95px' }}>
              <div>
                <div className="row">
                  <h3 className="text-capitalize" style={{ fontWeight: '900' }}>
                    Set Up Today in 10 Minutes or Less
                  </h3>
                </div>
                <br />
                <div className="row">
                  <div className="col-4">
                    <div className={classnames(style.setup)} style={{ padding: '25px 65px' }}>
                      <img height="140" src={getStartedOne} />
                    </div>
                    <div className="mt-2">
                      <div
                        className={classnames(
                          'rounded-circle text-center float-left mr-2 font-20',
                          style.setupDescription
                        )}
                      >
                        1
                      </div>
                      <div className="font-12">
                        Login with your email account (Gmail and Outlook supported) and grant
                        permissions!
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className={classnames(style.setup)} style={{ padding: '25px 59px' }}>
                      <img height="140" src={getStartedTwo} />
                    </div>

                    <div className="mt-2">
                      <div
                        className={classnames(
                          'rounded-circle text-center float-left mr-2 font-20',
                          style.setupDescription
                        )}
                      >
                        2
                      </div>
                      <div className="font-12">
                        Once your account is connected, you’ll be able to create a new meeting!
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className={classnames(style.setup)} style={{ padding: '25px 15px' }}>
                      <img height="140" src={getStartedThree} />
                    </div>
                    <div className="mt-2">
                      <div
                        className={classnames(
                          'rounded-circle text-center float-left mr-2 font-20',
                          style.setupDescription
                        )}
                      >
                        3
                      </div>
                      <div className="font-12 ">
                        Set your availability by choosing which days and times you would be
                        available for this meeting.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="container mt-3">
                    <div className="d-flex justify-content-center">
                      <a
                        className="font-weight-bold"
                        target="_blank"
                        href="/help-center/z311jb3li4-scheduler-fx"
                      >
                        View Help Docs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {meetWithMeSlug && <div className="mt-5"></div>}
            {!meetWithMeSlug && (
              <div
                className="row mt-5"
                style={{
                  paddingLeft: '95px',
                  backgroundImage: `url(${footerBg})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '377px',
                  backgroundColor: '#F9FBFF',
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-sm"></div>
                    <div className="col-lg-6 pl-5">
                      <h3 className="font-weight-bold" style={{ marginTop: '78px' }}>
                        We’re Here to Help!
                      </h3>
                      <p>
                        Contact our MarketingCloudFX MarTech Specialists for questions or to embed
                        SchedulerFX in your landing pages.
                      </p>

                      <a
                        href="mailto:mcfxsolutions@webfx.com"
                        target="_blank"
                        style={{ width: '219px' }}
                        type="button"
                        className="btn btn-success font-24 mr-4 text-capitalize"
                        rel="noreferrer"
                      >
                        <span>Email Support&nbsp;</span>
                        <span>
                          <svg
                            width="10"
                            height="11"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.00001 0.833344L4.17751 1.65584L7.43251 4.91668H0.333344V6.08334H7.43251L4.17751 9.34418L5.00001 10.1667L9.66668 5.50001L5.00001 0.833344Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </a>

                      <a
                        href="/scheduler/book-meeting/mcfx-solutions-30-min-meeting"
                        target="_blank"
                        style={{ width: '180px' }}
                        type="button"
                        className="btn btn-outline-primary font-24 text-capitalize"
                      >
                        <span>book a call</span>
                      </a>
                    </div>
                    <div className="col-sm"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
        <BannerModal
          show={showBannerModal}
          onClose={() => handlePageWrapperBlur(false, setShowBannerModal)}
          onAction={() => setShowSchedulerModal(!showSchedulerModal)}
        />
      </Container>
    </div>
  );
};

export default LandingPage;
