import { Icon, Button, Svg, Spinner } from '@webfx/core-web';
import { useStoreState } from 'easy-peasy';
import classnames from 'classnames';
import {
  useSitePlan,
  useCreateNutshellInstance,
  useAuth,
  useActiveSite,
  useNotifications,
} from '@webfx/web-hooks';

import planIconAncient from '@webfx/marketingcloud-web/src/assets/images/nutshell/plans/Ancient.svg';
import planIconLite from '@webfx/marketingcloud-web/src/assets/images/nutshell/plans/Lite.svg';
import planIconFoundation from '@webfx/marketingcloud-web/src/assets/images/nutshell/plans/Foundation.svg';
import planIconDataProfessional from '@webfx/marketingcloud-web/src/assets/images/nutshell/plans/Pro.svg';
import planIconInnovator from '@webfx/marketingcloud-web/src/assets/images/nutshell/plans/Innovator.svg';
import planIconInnovatorVIP from '@webfx/marketingcloud-web/src/assets/images/nutshell/plans/VIP.svg';
import nutshellIcon from '@webfx/marketingcloud-web/src/assets/svgs/nutshell-icon.svg';
import schedulerFXlogo from '../../../../assets/images/scheduler/schedulerfx-logo.svg';
import calendarPreview from '../../../../assets/images/scheduler/calendar-preview.png';
import getStartedOne from '../../../../assets/images/scheduler/hourglass.png';
import getStartedTwo from '../../../../assets/images/scheduler/google-and-office-365.png';
import getStartedThree from '../../../../assets/images/scheduler/calendar-config.png';

import features1 from '../../../../assets/images/scheduler/features/features1.png';
import features2 from '../../../../assets/images/scheduler/features/features2.png';
import features3 from '../../../../assets/images/scheduler/features/features3.png';
import features4 from '../../../../assets/images/scheduler/features/features4.png';
import features5 from '../../../../assets/images/scheduler/features/features5.png';
import features6 from '../../../../assets/images/scheduler/features/features6.png';

import excludeImg from '../../../../assets/images/scheduler/exclude.png';
import ellipseImg from '../../../../assets/images/scheduler/ellipse.png';
import excludeSmallImg from '../../../../assets/images/scheduler/exclude-small.png';
import ellipseTwoImg from '../../../../assets/images/scheduler/ellipse-341.png';
import ellipseThreeImg from '../../../../assets/images/scheduler/ellipse-340.png';

const plans = {
  1: {
    planIcon: planIconAncient,
  },
  2: {
    planIcon: planIconLite,
  },
  3: {
    planIcon: planIconFoundation,
    calendar: 10,
  },
  4: {
    planIcon: planIconDataProfessional,
    calendar: 20,
  },
  6: {
    planIcon: planIconInnovator,
    calendar: 40,
  },
  5: {
    planIcon: planIconInnovatorVIP,
    calendar: 'Unlimited',
  },
};

import styles from './LandingPageNutshell.module.css';

const features = [
  {
    text: 'Plan, book, and manage your appointments and meetings',
    subText: 'All from inside MarketingCloudFX',
    img: features1,
    position: 'right',
  },
  {
    text: 'Share your availability during working hours',
    subText: 'Directly from your Google Calendar or Microsoft 365 Calendar',
    img: features2,
    position: 'left',
  },
  {
    text: 'Include Zoom, Google Meet, and Microsoft Teams links',
    subText: 'in the invites received after booking a meeting',
    img: features3,
    position: 'right',
  },
  {
    text: 'Add forms to your SchedulerFX links',
    subText: 'To gather more information about attendees',
    img: features4,
    position: 'left',
  },
  {
    text: 'Automate booking confirmation emails',
    subText: 'And receive notifications for each appointment',
    img: features5,
    position: 'right',
  },
  {
    text: 'Collect your bookings in LeadManagerFX',
    subText: 'And see them on your contact timelines in Customer360FX',
    img: features6,
    position: 'left',
  },
];

/**
 * A functional component that renders the landing page for Nutshell.
 * It uses various hooks and components to manage state and render UI.
 * @returns {JSX.Element} The rendered React component.
 * return (
 *   <LandingPageNutshell />
 * )
 */
const LandingPageNutshell = () => {
  const { siteId } = useActiveSite();
  const { url } = useStoreState((state) => state.router);
  const billingPageUrl = url({ route: 'mcfxSettings', siteId, page: 'billing-plans' });
  const sitePlan = useSitePlan(true);
  let planId = sitePlan?.planId;

  if (!planId) {
    planId = 1;
  }

  const { toast } = useNotifications();

  const { isAdmin } = useAuth();

  const nutshell = useCreateNutshellInstance();

  const handleCreateNutshellInstance = () => {
    if (isAdmin) {
      toast.error(
        'Only client users can provision Nutshell accounts. Admin users cannot provision Nutshell accounts for their clients.'
      );
      return null;
    } else {
      nutshell.create();
    }
  };

  const ProvisionNutshellInstanceButton = () => {
    return (
      <Button
        className="font-14 text-white shadow-none"
        variant="success"
        onClick={handleCreateNutshellInstance}
        icon="rocket_launch"
        data-fx-name="upgradeYourPlanButton"
        disabled={
          nutshell.isLoading || nutshell.isProvisioning || nutshell.isErrored || nutshell.isCreating
        }
      >
        {nutshell.isProvisioning && <Spinner variant="white" className="mr-2" />}
        {!nutshell.isProvisioning && 'Start using SchedulerFX'}
      </Button>
    );
  };

  return (
    <div className="bg-white pt-5">
      <div>
        <div className="row"></div>

        <div className="m-0 row position-relative">
          <div className="col ml-5" style={{ marginTop: '110px', zIndex: '2' }}>
            <div className="mb-1">
              <Svg
                src={schedulerFXlogo}
                svgStyle={{
                  height: '55px',
                }}
              />
            </div>
            <div className="font-15 mb-2 ml-2 d-flex align-center bold align-middle">
              <div className="align-middle">Powered by</div>
              <Svg className="ml-1" svgStyle={{ maxHeight: '13px' }} src={nutshellIcon} />
            </div>
            <h3>Book Calls Faster, Close Sales Faster, and Move Past Your Competitors</h3>
            <div>
              <div className="d-flex align-items-center font-16 mb-3">
                <Icon className="mr-2 font-18 text-success">check</Icon>
                Provide a better call scheduling experience for your prospects with SchedulerFX by
                allowing them to easily select a time on your (or your teams) calendar that works
                best for their schedules.
              </div>
              <div className="d-flex align-items-center font-16 mb-3">
                <Icon className="mr-2 font-18 text-success">check</Icon>
                Set working hours, send your booking link, and eliminate back and forth when booking
                appointments.
              </div>
              <div>
                {planId > 2 && <ProvisionNutshellInstanceButton />}
                {planId < 3 && (
                  <Button
                    className="font-14 text-white shadow-none"
                    variant="success"
                    to={billingPageUrl}
                    icon="rocket_launch"
                    data-fx-name="upgradeYourPlanButton"
                  >
                    Upgrade to use SchedulerFX
                  </Button>
                )}

                {plans[planId]?.calendar && (
                  <div className="row mt-4">
                    <div className="col-1 mr-2">
                      <Icon className="mt-3 mr-2 font-18 text-orange">group</Icon>
                    </div>
                    <div className="col ml-sm-n4">
                      <div className="row text-dark">
                        {plans[planId]?.calendar} Calendar Users included
                      </div>
                      <div className="row text-secondary">
                        in your MarketingCloudFX {sitePlan?.name} Plan!
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col" style={{ zIndex: '2' }}>
            {sitePlan && (
              <div className="row justify-content-end mb-2">
                <div className={classnames([styles.plan, 'mr-3 pl-1 pr-1'])}>
                  <Svg
                    src={plans[planId]?.planIcon}
                    svgClassName={classnames(['mr-1', styles.planTierIcon])}
                  />
                  <span className={classnames([styles.planText])} data-fx-name="planName">
                    {sitePlan?.name}
                  </span>
                </div>
              </div>
            )}

            <div className="float-right row">
              <img height="500" src={calendarPreview} />
            </div>
          </div>
          <img className={classnames([styles.excludeImg])} src={excludeImg} />
          <img className={classnames([styles.ellipseImg])} src={ellipseImg} />
          <img className={classnames([styles.excludeSmallImg])} src={excludeSmallImg} />
          <img className={classnames([styles.ellipseOneImg])} src={ellipseTwoImg} />
          <img className={classnames([styles.ellipseThreeImg])} src={ellipseThreeImg} />
        </div>

        <div className="container" style={{ marginTop: '80px', padding: '0px 100px' }}>
          <div className="row d-flex justify-content-center">
            <h3 style={{ fontWeight: '700' }}>Set up today in 10 minutes or less!</h3>
          </div>
          <div className="row mt-4">
            <div className={classnames(['col-4'])}>
              <div className={classnames([styles.setupDiv])}>
                <div
                  className={classnames(
                    'rounded-circle text-center float-left mr-2 font-20',
                    styles.setupDescription
                  )}
                >
                  1
                </div>
                <div
                  className={classnames(styles.setup, styles.setupDivImageCenter)}
                  style={{ padding: '25px 65px' }}
                >
                  <img height="81" src={getStartedOne} />
                </div>
              </div>
              <div className="mt-2">
                <div className={classnames([styles.setupSubText])}>
                  Click the button to start using SchedulerFX! Please be patient as we create your
                  Scheduler instance
                </div>
              </div>
            </div>

            <div className={classnames(['col-4'])}>
              <div className={classnames([styles.setupDiv])}>
                <div
                  className={classnames(
                    'rounded-circle text-center float-left mr-2 font-20',
                    styles.setupDescription
                  )}
                >
                  2
                </div>
                <div
                  className={classnames(styles.setup, styles.setupDivImageCenter)}
                  style={{ padding: '25px 65px' }}
                >
                  <img height="81" src={getStartedTwo} />
                </div>
              </div>
              <div className="mt-2">
                <div className={classnames([styles.setupSubText])}>
                  Connect your Google Calendar or Office 365 Calendar
                </div>
              </div>
            </div>

            <div className={classnames(['col-4'])}>
              <div className={classnames([styles.setupDiv])}>
                <div
                  className={classnames(
                    'rounded-circle text-center float-left mr-2 font-20',
                    styles.setupDescription
                  )}
                >
                  3
                </div>
                <div
                  className={classnames(styles.setup, styles.setupDivImageCenter)}
                  style={{ padding: 'padding: 10px 0px 0px 100px;' }}
                >
                  <img height="120" src={getStartedThree} style={{ marginTop: '10px' }} />
                </div>
              </div>
              <div className="mt-2">
                <div className={classnames([styles.setupSubText])}>
                  Create a scheduler calendar configure your meeting’s settings!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ padding: '0px 100px', marginTop: '114px', maxWidth: '1270px' }}
        >
          <div className="border-top pt-lg-5 row d-flex justify-content-center">
            <h3 style={{ fontWeight: '700' }}>Book 10% More Appointments & Sales Meetings</h3>
          </div>

          <div>
            {features.map((feature) => (
              <div key={feature.text} className="row">
                {feature.position === 'left' && (
                  <div className="">
                    <img height="271" src={feature.img} />
                  </div>
                )}

                <div
                  style={feature.position === 'left' ? { marginLeft: '20px' } : {}}
                  className={classnames([
                    'col',
                    styles.featuresTextCenter,
                    feature.position === 'right' ? 'text-right' : 'text-left',
                  ])}
                >
                  <p className={classnames([styles.featuresText])}>{feature.text}</p>
                  <p>{feature.subText}</p>
                </div>
                {feature.position === 'right' && (
                  <div className="col-3">
                    <img height="271" src={feature.img} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="container pb-5">
          <div className={classnames([styles.centerText, 'pt-lg-5 row'])}>
            <h3 className={classnames([styles.getStartedText])}>Get started now:</h3>
          </div>

          {planId > 2 && (
            <div className={classnames([styles.centerText])}>
              <ProvisionNutshellInstanceButton />
            </div>
          )}

          {planId < 3 && (
            <>
              <div className={classnames([styles.centerText])}>
                <Button
                  className="font-14 text-white mb-3 shadow-none"
                  variant="success"
                  to={billingPageUrl}
                  icon="rocket_launch"
                  data-fx-name="upgradeYourPlanButton"
                >
                  Upgrade to use SchedulerFX
                </Button>
              </div>
              <div className={classnames([styles.centerText])}>
                <div style={{ width: '300px' }}>
                  <p className={classnames([styles.getStartedSubText])}>
                    Upgrade your MarketingCloudFX plan to receive access to SchedulerFX!
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

module.exports = LandingPageNutshell;
