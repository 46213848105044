import React from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import Icon from '../Icon/Icon';
import './ModuleHead.style.css';
import SiteSelector from '../SiteSelector/SiteSelector';

const ModuleHead = ({
  title,
  options,
  children,
  backToUri,
  backToLabel = 'Go Back',
  onSearch,
  settingUri,
  SelectComponent = SiteSelector,
  className,
  siteSelectorClass,
  disableSiteSelector = false,
  addPadding = true,
  siteId,
}) => {
  const Select = SelectComponent;
  const location = useLocation();

  return (
    <Container className={classnames(['module-head', className])} fluid={true}>
      <Row noGutters={true} className="align-items-end">
        <Col className="align-self-end">
          {!!backToUri && (
            <Link to={backToUri}>
              <Icon>keyboard_arrow_left</Icon> {backToLabel}
            </Link>
          )}
          {title}
          {children}
        </Col>
        <div
          className={classnames('align-self-end pb-2', siteSelectorClass, {
            'pb-3': addPadding && !settingUri, // settingUri adds its own padding
          })}
          style={{ maxWidth: 500 }}
        >
          {disableSiteSelector === false ? <Select options={options} /> : null}

          {!!settingUri && siteId && (
            <div className="d-flex justify-content-end align-self-center">
              <OverlayTrigger placement="top" overlay={<Tooltip>Settings</Tooltip>}>
                <Link to={settingUri} className="btn btn-sm text-gray-500 text-hover-blue-400 ml-2">
                  <Icon outlined className="font-24">
                    settings
                  </Icon>
                </Link>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default ModuleHead;
