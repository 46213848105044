import React, { useEffect, useMemo, useState, useContext } from 'react';
import { camelCase, isPlainObject, isString } from 'lodash';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useExpanded,
  usePagination,
  useBlockLayout,
} from 'react-table';
import { If, Then } from 'react-if';
import classNames from 'classnames';
import { useDeepMemo } from '@webfx/web-hooks';

import Pagination from '../Pagination/Pagination';
import Spinner from '../Spinner/Spinner';
import SelectInput from '../SelectInput/SelectInput';
import Icon from '../Icon/Icon';
import BulkActionsContext from '../../context/BulkActionsContext';
import BulkActions from '../BulkActions';
import EntityCell from './cells/EntityCell';
import HelperCell from './cells/HelperCell';

import './Table.style.css';

const cellMap = {
  entity: EntityCell,
  helper: HelperCell,
};

const handleClassModifiers = ({
  tableClassName,
  scrollable,
  handleSelect,
  variant,
  selectedRowIds = {},
  alwaysShowRowSelection,
}) => {
  const classes = [tableClassName];

  if (scrollable) {
    classes.push('table-scrollable');
  }
  if (handleSelect) {
    classes.push('table-selectable');
  }
  if (variant) {
    classes.push(`table-${variant}`);
  }
  if (Object.keys(selectedRowIds)?.length) {
    classes.push('table-select-active');
  }
  if (alwaysShowRowSelection) {
    classes.push('table-select-active-show');
  }

  return classNames(classes);
};

const CheckboxElement = ({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      className="custom-control custom-checkbox custom-control-lg"
      data-fx-name="checkboxContainer"
    >
      <label className="d-block">
        <input
          ref={resolvedRef}
          type="checkbox"
          {...rest}
          onClick={(event) => {
            event.stopPropagation();
          }}
          className="custom-control-input"
          data-fx-name="checkbox"
        />
        <div className="custom-control-label" data-fx-name="customLabel"></div>
      </label>
    </div>
  );
};

const IndeterminateCheckbox = React.forwardRef(CheckboxElement);

const Renderer = ({
  element = 'p',
  className = '',
  children,
  fixed = false,
  ...passThroughProps
}) => {
  if (fixed) {
    className = classNames(className, element);
    element = 'div';
  }
  const Component = element;

  return (
    <Component className={className} {...passThroughProps}>
      {children}
    </Component>
  );
};

const ColumnSortIndicator = ({ column }) => {
  if (!column.canSort) {
    return null;
  }

  return (
    <div className="sort-indicator" data-fx-name="arrowIcon">
      <Icon data-fx-name="arrow">arrow_upward</Icon>
    </div>
  );
};

const TableWrapper = ({
  responsive,
  className,
  children,
  loading,

  ...passThroughProps
}) => {
  if (responsive || className) {
    return (
      <div
        className={classNames([
          { 'table-responsive': responsive, 'table--is-loading': loading },
          className,
        ])}
        {...passThroughProps}
        data-fx-name={passThroughProps['data-fx-name'] || 'eventTable'}
      >
        {children}
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
/**
 *
 * @param root0
 * @param root0.columns
 * @param root0.data
 * @param root0.options
 * @param root0.onRowSelection
 * @param root0.tableClassName
 * @param root0.responsive
 * @param root0.sortable
 * @param root0.sortableHeaders
 * @param root0.fixedColumns
 * @param root0.scrollable
 * @param root0.className
 * @param root0.variant
 * @param root0.paginate
 * @param root0.paginationClassName
 * @param root0.manualPagination
 * @param root0.loading
 * @param root0.defaultPageCount
 * @param root0.defaultPageIndex
 * @param root0.defaultPageSize
 * @param root0.totalEntries
 * @param root0.onPageIndexChange
 * @param root0.onPageSizeChange
 * @param root0.showPaginationStatus
 * @param root0.width
 * @param root0.selectActions
 * @param root0.tableRef
 * @param root0.manualSortBy
 * @param root0.onSort
 * @param root0.disableMultiSort
 * @param root0.bulkActionContentClass
 * @param root0.bulkActionDialogClass
 * @param root0.initialState
 * @param root0.onBulkActionClose
 * @param root0.blurKey
 * @param root0.alwaysShowRowSelection
 */
const Table = ({
  columns,
  data,
  options,
  onRowSelection,
  tableClassName = '',
  responsive = true,
  sortable = true,
  sortableHeaders = [],
  fixedColumns = false,
  scrollable = false,
  className = '',
  variant,
  paginate,
  paginationClassName,
  manualPagination,
  loading = false,
  defaultPageCount = 1, // If not doing manual pagination, pass an accurate page count to avoid bugs
  defaultPageIndex = 0,
  defaultPageSize = 10,
  totalEntries,
  onPageIndexChange,
  onPageSizeChange,
  showPaginationStatus, // Show status message ex "Showing 1 to 10 of 50 entries"
  width,
  selectActions,
  tableRef,
  manualSortBy,
  onSort,
  disableMultiSort,
  bulkActionContentClass = '',
  bulkActionDialogClass = '',
  bulkActionModalClass = '',
  initialState = {},
  onBulkActionClose = () => {},
  blurKey = 'outsideLimit',
  alwaysShowRowSelection = false,
  ...passThroughProps
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const [activeToggleRow, setActiveToggleRow] = useState(null);
  const { setSelected } = useContext(BulkActionsContext);
  const handleSelect = useMemo(() => {
    if (selectActions) {
      return (selectedRows, flatSelectedRows) =>
        setSelected(flatSelectedRows.map((row) => row.original));
    }
    return onRowSelection;
  }, [selectActions, onRowSelection]);

  const [plugins, tableColumns] = useDeepMemo(() => {
    const plugins = [];
    const tableColumns = [
      ...columns
        .filter((c) => c)
        .map((column) => {
          if (column.component && cellMap[column.component]) {
            column = {
              ...column,
              Cell: cellMap[column.component]({
                ...column.componentProps,
                setActiveRow: (id) => setActiveToggleRow(Number(id)),
              }),
            };
          }
          if (column.headerComponent && cellMap[column.headerComponent]) {
            column = {
              ...column,
              Header: cellMap[column.headerComponent]({
                ...column.headerComponentProps,
                headerText: column.Header,
                isHeader: true,
              }),
            };
          }
          return column;
        }),
    ];

    if (sortable) {
      plugins.push(useSortBy);
    }

    plugins.push(useExpanded);

    if (paginate) {
      plugins.push(usePagination);
    }

    if (handleSelect) {
      plugins.push(useRowSelect);
    }

    if (fixedColumns) {
      plugins.push(useBlockLayout);
    }

    return [plugins, tableColumns];
  }, [columns, sortable, paginate, handleSelect, fixedColumns]);

  const rowClass = alwaysShowRowSelection ? 'cell-with-select-show' : 'cell-with-select';
  const table = useTable(
    {
      columns: tableColumns,
      data,
      manualPagination,
      pageCount: defaultPageCount,
      initialState: {
        pageSize: defaultPageSize,
        pageIndex: defaultPageIndex,
        ...initialState,
      },

      useControlledState: (state) => ({
        ...state,
        activeRow,
      }),
      manualSortBy,
      disableMultiSort,
      ...options,
    },
    (hooks) => {
      if (handleSelect && tableColumns.length) {
        hooks.visibleColumns.push(([firstColumn, ...columns]) => [
          // Let's make a column for selection
          {
            id: 'selection',
            ...firstColumn,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps, ...rest }) => (
              <div>
                <div className={`${rowClass} d-flex align-items-center`}>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
                {typeof firstColumn.Header === 'string' ? (
                  <div className="pl-2" data-fx-name="tableHeader">
                    {firstColumn.Header}
                  </div>
                ) : (
                  <firstColumn.Header {...{ getToggleAllRowsSelectedProps, ...rest }} />
                )}
              </div>
            ),
            customProps: {
              ...firstColumn.customProps,
              className: `selection-column ${firstColumn.customProps?.className}`,
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row, ...rest }) => {
              return (
                <div>
                  <div className={`${rowClass} d-flex align-items-center`} data-fx-name="rowToggle">
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                  {typeof firstColumn.Cell === 'string' ? (
                    <div>{firstColumn.Cell}</div>
                  ) : (
                    <firstColumn.Cell {...{ row, ...rest }} />
                  )}
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    },
    ...plugins
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { sortBy },

    // Pagination
    pageCount,
    gotoPage,
    setPageSize,
    state: { selectedRowIds, pageIndex, pageSize },
  } = table;

  if (isPlainObject(tableRef)) {
    tableRef.current = table;
  }
  let queue = rows;
  if (paginate) {
    queue = page;
  }

  const paginationStatus = useMemo(() => {
    if (!showPaginationStatus) {
      return;
    }

    const countTotal = totalEntries || data?.length;
    let countEnd = (pageIndex + 1) * pageSize;
    if (countEnd > countTotal) {
      countEnd = countTotal;
    }
    const countStart = pageIndex === 0 ? 1 : pageIndex * pageSize + 1;

    return (
      <div className="PW__entries-container" data-fx-name="paginationInfo">
        Showing {!countTotal && 'entries'} {countStart} to {countEnd}{' '}
        {countTotal && `of ${countTotal} entries`}
      </div>
    );
  }, [data?.length, pageIndex, pageSize, totalEntries]);

  const handleSortableHeaderProps = (column) => {
    if (
      (sortableHeaders.length === 0 && sortable) ||
      (sortableHeaders.length > 0 && sortableHeaders.includes(column.Header))
    ) {
      return {
        ...column.getSortByToggleProps(),
        className: classNames(column?.customProps?.className ?? '', 'sortable', {
          'sorting-asc': column.isSorted && !column.isSortedDesc,
          'sorting-desc': column.isSorted && column.isSortedDesc,
          'hover-sort-desc': column.isSorted && !column.isSortedDesc,
          'hover-sort-asc': !column.isSorted || (column.isSorted && column.isSortedDesc),
        }),
      };
    }
  };

  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
    return;
  }, [sortBy]);

  useEffect(() => {
    if (handleSelect) {
      handleSelect(selectedRowIds, selectedFlatRows);
    }
  }, [JSON.stringify(selectedRowIds)]);

  useEffect(() => {
    if (paginate) {
      gotoPage(Number(defaultPageIndex));
    }
  }, [defaultPageIndex]);

  tableClassName = handleClassModifiers({
    // TODO switch to classnames
    tableClassName,
    scrollable,
    handleSelect,
    variant,
    selectedRowIds,
    alwaysShowRowSelection,
  });

  return (
    <>
      {selectActions && (
        <BulkActions
          actions={selectActions}
          modalContentClass={bulkActionContentClass}
          modalDialogClass={bulkActionDialogClass}
          modalClass={bulkActionModalClass}
          onClose={onBulkActionClose}
        />
      )}
      <TableWrapper
        responsive={responsive}
        className={className}
        loading={loading}
        {...passThroughProps}
      >
        <Renderer
          element="table"
          className={classNames('table', tableClassName, loading && 'table-loading')}
          data-fx-name={passThroughProps['data-fx-name'] || 'eventTable'}
          {...getTableProps()}
          fixed={fixedColumns}
          style={{ ...(width && { width }) }}
        >
          <Renderer element="thead" fixed={fixedColumns}>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <Renderer
                element="tr"
                {...headerGroup.getHeaderGroupProps()}
                fixed={fixedColumns}
                key={headerGroupIndex}
                data-fx-name="tableRow"
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <Renderer
                      element="th"
                      {...(column.customProps ? column.customProps : {})}
                      {...column.getHeaderProps(handleSortableHeaderProps(column))}
                      fixed={fixedColumns}
                      key={index}
                      data-fx-name="tableHeader"
                    >
                      {column.render('Header')}
                      <If
                        condition={
                          sortableHeaders.length > 0 && sortableHeaders.includes(column.Header)
                        }
                      >
                        <Then>
                          <ColumnSortIndicator column={column} />
                        </Then>
                      </If>
                      <If condition={sortableHeaders.length === 0}>
                        <Then>
                          <ColumnSortIndicator column={column} />
                        </Then>
                      </If>
                    </Renderer>
                  );
                })}
              </Renderer>
            ))}
          </Renderer>

          <Renderer element="tbody" {...getTableBodyProps()} fixed={fixedColumns}>
            {queue.map((row, i) => {
              prepareRow(row);
              return (
                <Renderer
                  element="tr"
                  fixed={fixedColumns}
                  {...row.getRowProps()}
                  className={classNames({
                    'bg-blue-100': row.isExpanded || row.depth > 0,
                    'blurred-row': row.original[blurKey],
                  })}
                  onMouseEnter={() => setActiveRow(i)}
                  onMouseLeave={() => setActiveRow(null)}
                  key={i}
                  data-fx-name="tableRow"
                >
                  {row.cells
                    .filter((cell) => {
                      if (typeof cell.column.shouldRender === 'undefined') {
                        return true;
                      }

                      if (typeof cell.column.shouldRender === 'function') {
                        return cell.column.shouldRender(cell);
                      }

                      return cell.columns.shouldRender;
                    })
                    .map((cell, index) => {
                      let customProps = {};
                      if (typeof cell.column.customProps === 'function') {
                        customProps = cell.column.customProps(cell);
                      } else if (cell.column.customProps) {
                        customProps = cell.column.customProps;
                      }
                      const className = `${customProps.className || ''} ${
                        i === activeToggleRow && customProps.className?.includes('sticky')
                          ? 'toggle-active'
                          : ''
                      }`;

                      return (
                        <Renderer
                          element="td"
                          fixed={fixedColumns}
                          {...cell.getCellProps()}
                          {...customProps}
                          className={className.trim()}
                          key={index}
                          data-fx-name={
                            isString(cell.column?.Header)
                              ? camelCase(cell.column.Header)
                              : camelCase(cell.column?.id)
                          }
                        >
                          <div data-fx-name="cellValue">{cell.render('Cell')}</div>
                        </Renderer>
                      );
                    })}
                </Renderer>
              );
            })}
          </Renderer>
        </Renderer>

        {/* show initial table loader for when there is no data */}
        {queue && queue.length === 0 && loading ? (
          <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
            <Spinner animation="border" size="sm" className="table-spinner" />
          </div>
        ) : null}
      </TableWrapper>
      {paginate && (
        <div
          className={classNames(
            'PW mt-3 mb-3 text-center row align-items-center justify-content-around',
            paginationClassName
          )}
          data-fx-name="paginationSection"
        >
          {showPaginationStatus && <div className="">{paginationStatus}</div>}
          <div data-fx-name="pagination">
            <Pagination
              onPageChange={({ selected }) => {
                gotoPage(Number(selected));
                if (onPageIndexChange) {
                  onPageIndexChange(Number(selected));
                }
              }}
              pageCount={pageCount}
              forcePage={pageIndex}
              withBorder={true}
            />
          </div>
          <div data-fx-name="showEntries">
            <span data-fx-name="showLabel">Show</span>
            <SelectInput
              className="PW_Select"
              classNamePrefix="PW_Select"
              options={[...[10, 20, 30, 40, 50, 100].map((value) => ({ label: value, value }))]}
              field={{
                name: 'pageSize',
                value: pageSize,
              }}
              form={{
                setFieldValue: (name, value) => {
                  setPageSize(Number(value));
                  if (onPageSizeChange) {
                    onPageSizeChange(Number(value));
                  }
                },
                touched: { select: true },
              }}
            />
            <span data-fx-name="entriesLabel">Entries</span>
          </div>
        </div>
      )}
    </>
  );
};

Table.cellRenderer =
  (children = null) =>
  ({ cell }) => {
    let cellComponent = String(cell.value);

    if (typeof children === 'function') {
      cellComponent = children(cell);
    } else if (children) {
      cellComponent = children;
    }

    const CommonToggle = ({ row }) => {
      return (
        <span {...row.getToggleRowExpandedProps({})}>
          <Icon className={'position-absolute text-blue-600'}>
            {row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
          </Icon>
        </span>
      );
    };

    return (
      <>
        {cellComponent}
        <If condition={cell.row.canExpand}>
          <Then>
            <CommonToggle row={cell.row} />
          </Then>
        </If>
      </>
    );
  };

export default Table;
