import React from 'react';
import { Modal, Icon, Button, Link, FormikInput, InputLabel } from '@webfx/core-web';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import { useStoreState } from 'easy-peasy';
import numbro from 'numbro';

import { TERMS_OF_USE_LINK } from '@webfx/marketingcloud-web/src/constants/billing';
import styles from './PlanUpgradeModal.module.css';
import { useActiveSite } from '@webfx/web-hooks';

// terms of use link

/**
 * Calculates recurring pricing for addons
 * @returns {integer} - total addon-quantity * addons-price
 */
const calculateMonthlyRecurring = (addon, formData) => {
  let finalAddonPrice = addon.price;

  if (formData[addon.quantityName] && formData[addon.quantityName] > 0) {
    finalAddonPrice = formData[addon.quantityName] * addon.price;
  }

  return numbro(finalAddonPrice).formatCurrency();
};

const PlanUpgradeModal = ({
  show,
  onClose,
  title,
  text,
  addon,
  handleOnSubmit,
  component = null,
}) => {
  const url = useStoreState((state) => state.router.url);
  const { siteId } = useActiveSite();

  if (!addon && !component) {
    return null;
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      showFooter={false}
      showHeader={false}
      backdrop={true}
      keyboard={false}
      passedClassNames={classnames(styles.modalDialog)}
      contentClassName={classnames(styles.modalContent)}
    >
      <div className={styles.modalContainer}>
        <div className={'d-flex justify-content-end align-items-center'}>
          <Icon onClick={onClose} className={classnames(styles.closeButton)}>
            close
          </Icon>
        </div>

        <div className="m-3 d-flex flex-column align-items-center">
          <h3 className={styles.title}>{title}</h3>
          <span className={styles.description}>{text}</span>
        </div>

        {!component ? (
          <Formik
            initialValues={{
              [addon.quantityName || addon.itemType]: addon.limitQuantity || addon.quantity,
              tosAgree: false,
            }}
            onSubmit={handleOnSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <Modal.Body>
                  <div className={classnames('p-3', styles.addonSection)}>
                    <h3 className={classnames('mb-2', styles.addonText)}>{addon.text}</h3>

                    <span className={classnames('mb-1', styles.addonQuantityText)}>Quantity</span>

                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div className="d-flex align-items-center">
                        {addon.quantityName && (
                          <FormikInput
                            step={addon.quantity}
                            className="mr-2 mb-0"
                            type="number"
                            name={addon.quantityName}
                            min={addon.limitQuantity || addon.quantity}
                            max={1000}
                          />
                        )}

                        {!addon?.quantityName && addon.quantity === -1 && (
                          <div className="badge badge-pill badge-warning mr-2 text-white p-2">
                            UNLIMITED!
                          </div>
                        )}

                        <div>
                          <h3 className={classnames('mb-0', styles.addonPrice)}>
                            {calculateMonthlyRecurring(addon, values)}
                          </h3>
                          <span className={classnames('mb-0', styles.addonRecurring)}>
                            Monthly Recurring
                          </span>
                        </div>
                      </div>

                      <Icon className={styles.addonIcon} outlined>
                        {addon.icon}
                      </Icon>
                    </div>
                    {addon.quantityName && (
                      <span className={styles.addonIncrement}>
                        Available in increments of {addon.increments}
                      </span>
                    )}
                  </div>

                  <div className="mt-3">
                    <span className={styles.tosDescription}>
                      By checking this box and submitting this upgrade request, I agree with the
                      additional charge, description and details outlined, and I agree to the Terms
                      and Conditions found by{' '}
                      <a
                        href={TERMS_OF_USE_LINK}
                        className={styles.tosInfoButton}
                        target="_blank"
                        rel="noreferrer"
                      >
                        clicking here
                      </a>
                      .
                    </span>
                  </div>
                </Modal.Body>

                <div>
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <div className="d-flex mr-4 align-items-center">
                      <FormikInput
                        className={classnames('mr-2 mb-0', styles.tosCheckbox)}
                        type="checkbox"
                        id="tosAgree"
                        name="tosAgree"
                        checked={values.tosAgree}
                      />
                      <InputLabel
                        className={classnames('mb-0', styles.tosLabel)}
                        text="I agree, and I accept these terms and conditions."
                      />
                    </div>
                    <div className="d-flex">
                      <Button
                        className={classnames('mr-2', styles.cancelButton)}
                        variant="primary"
                        text="Cancel"
                        type="button"
                        onClick={onClose}
                        outline
                        disabled={isSubmitting}
                      />
                      <Button
                        className={styles.submitButton}
                        variant="primary"
                        text="Submit Add On"
                        type="submit"
                        onClick={() => {}}
                        isLoading={isSubmitting}
                        disabled={isSubmitting || !values.tosAgree}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between w-100 align-items-center mt-3">
                    <Link
                      to={url({ route: 'mcfxSettings', siteId, page: 'billing-plans' })}
                      className={styles.currentPlanButton}
                    >
                      View Current Plan
                    </Link>
                    <span className={styles.addonDisclaimer}>
                      Your order will be submitted to accounting and will be reflected in your next
                      invoice.
                    </span>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          component
        )}
      </div>
    </Modal>
  );
};

export default PlanUpgradeModal;
