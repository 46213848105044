import {
  useAuth,
  useNotifications,
  useSitePlan,
  useSitePlanMutator,
  useActiveSite,
} from '@webfx/web-hooks';
import { getPlanBlockQty, getPlanLevers } from '@webfx/utils';
import classnames from 'classnames';
import { useStoreState } from 'easy-peasy';
import isBoolean from 'lodash/isBoolean';
import React, { useCallback, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { logError } from '../../utils';
import Button from '../Button/Button';
import IconLabel from '../IconLabel/IconLabel';

// component
import PlanUpgradeModal from '../PlanUpgradeModal';

const PlanAlert = ({
  className = 'mt-4 ml-4 mr-4',
  text,
  title,
  addonName = '',
  hiddenText = 'Attention Required!',
  btnText = 'Add Now!',
  btnIcon = 'add_circle',
  redirect,
  monthUseCount = 0,
  ignoreAdmin = false,
  ignoreButton = false,
  redirectURl = '',
}) => {
  const history = useHistory();
  const sitePlan = useSitePlan();
  const { toast } = useNotifications();
  const sitePlanMutator = useSitePlanMutator();
  const addons = getPlanBlockQty(null, true); // grab all plan blocks

  const [show, setShow] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const url = useStoreState((state) => state.router.url);
  const isAdmin = useAuth().isAdmin;
  const { siteId } = useActiveSite();
  const [hide, setHide] = useState(localStorage.getItem(`hidePlanAlert.${addonName}`));

  const handleShowModal = useCallback(() => {
    setShow(true);
  }, []);

  const handleOnHide = useCallback(() => {
    setShow(false);
  }, []);

  const handleRedirect = useCallback(() => {
    if (redirectURl) {
      setRedirectTo(redirectURl);
    } else {
      setRedirectTo(url({ route: 'mcfxSettings', siteId, page: 'billing-plans' }));
    }
  }, []);

  if (!sitePlan || !sitePlan?.limits || !sitePlan?.meta) {
    return null;
  }

  const addon = addons.find((addon) => addonName && addon.itemType === addonName);
  const needsRedirect = redirect || sitePlan.meta.supportsLegacy || sitePlan.meta.isLite;
  const updatedBtnText = needsRedirect ? 'Upgrade Plan' : btnText;

  let doesNotRequireAlert;
  const planAddonLimit = sitePlan.limits[addonName] || sitePlan.meta[addonName];

  if (isBoolean(planAddonLimit)) {
    doesNotRequireAlert = planAddonLimit;
  } else {
    if (monthUseCount === 0) {
      doesNotRequireAlert = false;
    } else {
      const monthLimitDifference = planAddonLimit - monthUseCount;
      doesNotRequireAlert = monthLimitDifference > 0;
    }
  }

  if (planAddonLimit === -1 || doesNotRequireAlert || (isAdmin && !ignoreAdmin)) {
    return null;
  }

  // set addon quantity
  if (addon) {
    addon.limitQuantity = null;

    if (planAddonLimit) {
      addon.limitQuantity = planAddonLimit;
    }
  }

  /**
   * Handles processing plan upgrade
   * @param {object} formData
   * @param {object} actions
   */
  const handleOnSubmit = async (formData, formActions) => {
    try {
      const currentPlanLever = getPlanLevers(sitePlan.planId, sitePlan.subscriptions, true);

      const planObject = {
        currentPlanId: sitePlan.planId,
        currentPlanItemCode: currentPlanLever,
        planAddons: addons.reduce((accum, addon) => {
          if (formData[addon.quantityName] || formData[addon.itemType]) {
            const addonQty = formData[addon.quantityName] || addon.quantity;

            return {
              ...accum,
              [addon.itemType]: {
                quantity: addonQty,
                itemCode: addon.itemCode,
              },
            };
          }
          return accum;
        }, {}),
      };

      const isUpdated = await sitePlanMutator.mutateAsync({
        _method: 'patch',
        _id: siteId,
        siteId,
        plan: planObject,
      });

      if (isUpdated?.planResults && isUpdated.planResults.status === 'success') {
        toast.success('Request submitted successfully, please hold on, thank you.');

        setTimeout(() => {
          formActions.setSubmitting(false);
          setShow(false);
          history.go(0);
        }, 2000);
      }

      return true;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      logError(e);
    }
  };

  if (hide) {
    return (
      <div className={classnames('d-flex', className)}>
        <div className="flex-grow-1" />
        <Button
          className="text-decoration-none text-primary"
          variant="link"
          iconClassName="text-warning"
          icon="warning"
          text={hiddenText}
          onClick={() => {
            setHide(false);
            localStorage.removeItem(`hidePlanAlert.${addonName}`);
          }}
        />
      </div>
    );
  }

  // handle redirects
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <div
        className={`alert alert-warning d-flex justify-content-between aligns-item-center ${className}`}
      >
        <IconLabel
          className="align-items-center"
          iconClassName="icon-warning-alert"
          icon="warning"
          label={text}
        />

        <div className="d-flex aligns-item-center text-center">
          {!ignoreButton && (
            <Button
              className="mr-2 text-white"
              variant="primary"
              text={updatedBtnText}
              icon={needsRedirect ? '' : btnIcon}
              iconPosition={btnIcon ? 'right' : null}
              iconTrailing
              iconOutlined
              onClick={needsRedirect ? handleRedirect : handleShowModal}
            />
          )}

          <Button
            className="p-0 text-warning"
            icon="visibility_off"
            onClick={() => {
              setHide(true);
              localStorage.setItem(`hidePlanAlert.${addonName}`, true);
            }}
            outlined
          />
        </div>
      </div>

      {/* Upgrade Modal */}
      <PlanUpgradeModal
        show={show}
        onClose={handleOnHide}
        title={title}
        text={text}
        addon={addon}
        handleOnSubmit={handleOnSubmit}
      />
    </>
  );
};

export default PlanAlert;
