import pick from 'lodash/pick';
import { useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { useAuth, useActiveSite } from '@webfx/web-hooks';
import { ResizeContext } from '../../context';
import store from '../../store';
import Icon from '../Icon/Icon';
import { Toggle } from '../SelectMenu/SelectMenu';
import { Menu } from './Menu';

import '../SelectMenu/SelectMenu.style.css';
import './SiteSelector.style.css';

const SiteSelector = ({ forRole = null }) => {
  const [selected, setSelected] = useState(null);
  const activeSite = useActiveSite();
  const isAdmin = useAuth().isAdmin;
  const { url } = store.getState().router;
  const history = useHistory();

  useEffect(() => {
    if (!selected) {
      return;
    }
  }, [selected]);

  useEffect(() => {
    setSelected(pick(activeSite, ['name', 'url', 'status']));
  }, [activeSite?.siteId]);

  const toogleLabel = useMemo(() => {
    if (!activeSite) {
      return isAdmin ? 'Select a Client' : 'Select a Profile';
    }

    return activeSite.name;
  }, [activeSite?.siteId]);

  return (
    <div id="container-div" className="switcher-width">
      <Dropdown className="">
        <Dropdown.Toggle as={Toggle} style={{ width: '100%' }}>
          <span className="flex-grow-1 text-left">{toogleLabel}</span>
        </Dropdown.Toggle>
        {!!activeSite && (
          <button
            className="select-menu-clear"
            onClick={() => {
              setSelected(null);
              history.push(url({ route: 'mcfxDashboard' }));
            }}
          >
            <Icon>close</Icon>
          </button>
        )}
        <ResizeContext.Provider>
          <Dropdown.Menu as={Menu} forRole={forRole} className="select-menu-menu" />
        </ResizeContext.Provider>
      </Dropdown>
    </div>
  );
};

export default SiteSelector;
