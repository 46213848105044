import { useState, useEffect } from 'react';
import moment from 'moment';
import DateTime from 'luxon/src/datetime';
import 'react-dates/initialize';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';

import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.style.css';

const DatePicker = ({
  value = null,
  onDateChange = null,
  className = null,
  enableOutsideDays = true,
  renderCalendarInfo = null,
  isOutsideRange = (day) => moment().diff(day) < 0,
  id,
  ...props
}) => {
  const [date, setDate] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setDate(value ? moment(value) : null);
  }, [value]);

  const getYears = function () {
    const currentYear = DateTime.local().year;
    const years = [];
    let startYear = currentYear - 70;
    for (let i = startYear; i <= currentYear; i++) {
      years.push(startYear++);
    }

    return years;
  };

  return (
    <div className={className} data-fx-name="datePicker">
      <SingleDatePicker
        id={id}
        date={date}
        monthFormat="MMMM YYYY"
        onDateChange={(value) => {
          setDate(value);
          if (onDateChange !== null) {
            onDateChange(value);
          }
        }}
        onFocusChange={({ focused }) => {
          setIsFocused(focused);
        }}
        focused={isFocused}
        placeholder="Date"
        required={false}
        enableOutsideDays={enableOutsideDays}
        isOutsideRange={isOutsideRange}
        numberOfMonths={1}
        renderCalendarInfo={() => {
          if (renderCalendarInfo) {
            return renderCalendarInfo({ setDate });
          }

          return null;
        }}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <div className="DayPickerNavigation_selects" data-fx-name="datePickerCalendar">
            <div>
              <select
                className="DayPickerNavigation_select DayPickerNavigation_select--month"
                value={month.month()}
                onChange={(e) => {
                  onMonthSelect(month, e.target.value);
                }}
              >
                {moment.months().map((label, value) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                className="DayPickerNavigation_select DayPickerNavigation_select--year"
                value={month.year()}
                onChange={(e) => {
                  onYearSelect(month, e.target.value);
                }}
              >
                {getYears().map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
