import React from 'react';
import { createContextStore, action, computed } from 'easy-peasy';

export const MenuContext = createContextStore({
  search: '',
  setSearch: action((state, payload) => {
    state.search = payload;
  }),
});

// const ResizeContext = React.createContext();

export const withMenuContext = (Menu) => (props) =>
  (
    <MenuContext.Provider>
      <Menu {...props} />
    </MenuContext.Provider>
  );
