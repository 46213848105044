import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import useDealChannelStats from '@webfx/marketingcloud-web/src/hooks/analytics/useDealChannelStats';
import IntegrationSetupModal from './IntegrationSetupModal';

const useIntegrationSetupModal = () => {
  const [showModal, setShowModal] = useState(false);
  const startDate = DateTime.local().minus({ days: 90 }).toISODate();
  const endDate = DateTime.local().toISODate();
  const { data: wonDeals } = useDealChannelStats({
    startDate,
    endDate,
    dateColumn: 'close_at',
    category: 'closed won',
  });

  const { data: lostDeals } = useDealChannelStats({
    startDate,
    endDate,
    dateColumn: 'close_at',
    category: 'closed lost',
  });

  useEffect(() => {
    if (wonDeals?.totalCount === 0 && lostDeals?.totalCount === 0) {
      setShowModal(true);
    }
  }, [wonDeals, lostDeals]);

  return {
    showModal,
    setShowModal,
    IntegrationSetupModal,
  };
};

export default useIntegrationSetupModal;
