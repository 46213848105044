import React from 'react';
import { useAuth } from '@webfx/web-hooks';

import Preloader from '../components/Preloader';
import { PermissionsAbility, PermissionsContext } from '../context/PermissionsContext';
import { useQuery } from '../api';

const PERMISSIONS_REFRESH_INTERVAL_MINS = 10;

const PermissionsProvider = ({ children }) => {
  const userId = useAuth().userId;
  const accessToken = window.localStorage.getItem('accessToken');

  const permissions = useQuery(['permissions', userId], {
    onSuccess: (permissions) => {
      if (permissions) {
        PermissionsAbility.update([...permissions, { action: 'read', subject: 'platform' }]);
      }
    },
    enabled: !!userId && !!accessToken,
    refetchInterval: PERMISSIONS_REFRESH_INTERVAL_MINS * 60 * 1000,
  });

  // ensure that the rules are fully updated before allowing app to render
  if (permissions.isLoading || (userId && PermissionsAbility.cannot('read', 'platform'))) {
    return <Preloader />;
  }

  return (
    <PermissionsContext.Provider value={PermissionsAbility}>{children}</PermissionsContext.Provider>
  );
};

export default PermissionsProvider;
