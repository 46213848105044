import classnames from 'classnames';
import React, { useEffect } from 'react';
import { If, Then } from 'react-if';
import { Link, useRouteMatch } from 'react-router-dom';

import { useAuth } from '@webfx/web-hooks';
import { ResizeContext } from '../../context/ResizeContext';
import store from '../../store';
import Badge from '../Badge/Badge';
import SelectMenu from '../SelectMenu/SelectMenu';

import '../SiteSelector/SiteSelector.style.css';

const DropdownItem = React.forwardRef(
  ({ href, children, className, data, index, ...props }, ref) => {
    const { url } = store.getState().router;
    let { path } = useRouteMatch();

    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    const isAdmin = useAuth().isAdmin;

    const setSize = ResizeContext.useStoreActions((actions) => actions.setSize);

    const idIndex = path.search(/:[a-zA-Z]/);

    // cut off any singular url routes
    // as ids will differ per assoc.
    if (-1 !== idIndex) {
      path = path.slice(0, idIndex);
    }

    href = href || url({ route: path, companyId: data.companyId });

    useEffect(() => {
      if (resolvedRef.current) {
        setSize({ id: index, size: resolvedRef.current.getBoundingClientRect().height });
      }
    }, [defaultRef.current]);

    return (
      <div
        key={`site-${data.companyId}`}
        ref={resolvedRef}
        className={`site-select-item site-select-item-${data.companyId} ${className}`}
      >
        <Link to={href} {...props}>
          <div className="d-flex justify-content-between align-items-center">
            <div className={classnames(['site-group', { 'w-100': !isAdmin }])}>
              <h4 className="site-select-name">{children}</h4>
            </div>
            <If condition={!!isAdmin}>
              <Then>
                <Badge variant={data.active ? 'success' : 'light'} pill>
                  {data.active ? 'Active' : 'Inactive'}
                </Badge>
              </Then>
            </If>
          </div>
        </Link>
      </div>
    );
  }
);

const CompanySelect = ({ options, ...props }) => {
  const { viewingId, items } = store.getState().companies;
  const isAdmin = useAuth().isAdmin;

  options = options || items;

  if (options.length < 2) {
    return null;
  }

  return (
    <SelectMenu
      options={options}
      {...props}
      defaultValue={viewingId}
      optionValue="companyId"
      optionLabel="name"
      itemComponent={DropdownItem}
      searchPlaceholder={isAdmin ? 'Search for a Client' : 'Search for a Profile'}
      searchProp={['name']}
      estimatedItemSize={60}
    />
  );
};

export default CompanySelect;
