import { Card } from '../Card';

import './ErrorPage.styles.css';

import notFound from './not-found.png';

const ErrorPage = ({ title, subtitle, image, children }) => {
  if (!image) {
    image = notFound;
  }

  return (
    <div className="container-fluid py-3 h-100">
      <Card className={'h-100'}>
        <Card.Body>
          <div className="d-flex justify-content-center pt-5 h-100">
            <div className={'flex-grow-0'}>
              <div className={'ErrorPage d-flex'}>
                <div className="ErrorPage-image mr-5">
                  <img src={image} />
                </div>

                <div className="ErrorPage-info d-flex flex-column">
                  <h1>{title}</h1>
                  <h2>{subtitle}</h2>

                  {children}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ErrorPage;
