import { ResizeContext } from '../../context/ResizeContext';
import EventList from './EventList';
import { useSessionedImpressions } from './useSessionedImpressions';

import './Timeline.css';

const Timeline = ({ className, data = null, height = 200 }) => {
  const impressions = useSessionedImpressions(data);

  return (
    <div className={`timeline ${className}`}>
      <ResizeContext.Provider>
        {impressions?.length && <EventList events={impressions} height={height} />}
      </ResizeContext.Provider>
    </div>
  );
};

export default Timeline;
