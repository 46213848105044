import { Button, Icon, Modal, Svg } from '@webfx/core-web';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Calendar from '@webfx/marketingcloud-web/src/assets/images/banner-modal/scheduler/calendar.svg';
import Embed from '@webfx/marketingcloud-web/src/assets/images/banner-modal/scheduler/embed.svg';
import File from '@webfx/marketingcloud-web/src/assets/images/banner-modal/scheduler/file.svg';
import logo from '@webfx/marketingcloud-web/src/assets/images/logos/logo-schedullerfx.svg';
import styles from './BannerModal.module.css';

const BannerModal = ({ show, onClose, onAction }) => {
  const [sendingRequest] = useState(false);
  const modalBodyRef = useRef(null);
  const prevState = useRef(null);

  const steps = [
    {
      icon: Calendar,
      text: 'Allow your prospects to easily select a time on your (or your teams’) calendar that works best for their schedules.',
    },
    {
      icon: File,
      text: 'Set working hours, send your booking link, and eliminate back and forth when booking appointments.',
    },
    {
      icon: Embed,
      text: 'You can even embed SchedulerFX right on your website landing pages! ',
    },
  ];

  /**
   * On mount, set up click listener to listen to
   * outside events. This is to close the modal
   * when there is an outside click
   */
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalBodyRef.current && !modalBodyRef.current.contains(event.target)) {
        if (prevState.current === false) {
          prevState.current = show;
          onClose();
        } else {
          prevState.current = !show;
        }
      }
    };

    // Bind the event listener
    document.addEventListener('click', handleOutsideClick);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [modalBodyRef, prevState, show]);

  return (
    <Modal
      centered
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      showFooter={false}
      showHeader={false}
      backdrop={false}
      keyboard={false}
      size="lg"
      passedClassNames={classnames(styles.modalDialog)}
      contentClassName={classnames(styles.modalContent)}
    >
      <div ref={modalBodyRef}>
        <div className={styles.header}>
          <div className={styles.closeWrapper}>
            <div
              className={styles.close}
              onClick={() => {
                prevState.current = show;
                onClose();
              }}
            >
              <Icon>close</Icon>
            </div>
          </div>
        </div>

        <div className={`d-flex flex-column align-items-center justify-content-center`}>
          <div className="font-20">
            Provide a better call scheduling experience for your prospects with
          </div>
          <Svg className={styles.logo} src={logo} svgProps={{ fill: 'white' }} />
        </div>

        <div className={'d-flex flex-col justify-content-center align-items-center mt-5'}>
          <div className={`row ${styles.stepsWrapper}`}>
            {steps.map((step, idx) => (
              <div className="col" key={idx}>
                <div className="d-flex justify-content-center">
                  <Svg className={styles.icon} src={step.icon} />
                </div>
                <div className="text-center">{step.text}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            className={styles.button}
            outline={false}
            centerContent
            variant="success"
            text="Start Scheduling Now!"
            uppercase={false}
            icon="arrow_forward"
            iconTrailing={true}
            iconOutlined={false}
            iconPosition="right"
            isLoading={sendingRequest}
            onClick={() => {
              onAction();
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BannerModal;
