import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { DisplayError } from '../../components';

function DisplayErrorBoundary({ children, ...props }) {
  return (
    <ErrorBoundary {...props} FallbackComponent={DisplayError}>
      {children}
    </ErrorBoundary>
  );
}

DisplayErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  props: PropTypes.object,
};

export default DisplayErrorBoundary;
