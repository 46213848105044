import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useErrorHandler } from 'react-error-boundary';

import './assets/css/bootstrap.css';
import './assets/css/react-query.css';
import './assets/css/filepond.css';

import ActionBar from './components/ActionBar';
import AddRemove from './components/AddRemove';
import AlphabeticFilter from './components/AlphabeticFilter/AlphabeticFilter';
import Avatar from './components/Avatar/Avatar';
import Button from './components/Button/Button';
import Badge from './components/Badge/Badge';
import Can from './components/Can';
import Card from './components/Card/Card';
import CardAvatar from './components/Card/CardAvatar/CardAvatar';
import CardTitle from './components/Card/CardTitle/CardTitle';
import CardSubTitle from './components/Card/CardSubTitle/CardSubTitle';
import CardEditLink from './components/Card/CardEditLink/CardEditLink';
import CardInfo from './components/Card/CardInfo/CardInfo';
import CardAllCommentatorsAvatars from './components/Card/CardAllCommentatorsAvatars/CardAllCommentatorsAvatars';
import Checkbox, { FormikCheckbox } from './components/Checkbox/Checkbox';
import CompanySelect from './components/CompanySelect/CompanySelect';
import CompanyCheckboxes from './components/CompanyCheckboxes/CompanyCheckboxes';
import ConditionalWrapper from './components/ConditionalWrapper/ConditionalWrapper';
import CheckboxGroup from './components/CheckboxGroup/CheckboxGroup';
import ContentContainer from './components/ContentContainer/ContentContainer';
import Collapse from './components/Collapse';
import DatePicker from './components/DatePicker/DatePicker';
import DefaultDatePicker from './components/DefaultDatePicker/DefaultDatePicker';
import DateRangePicker from './components/DateRangePicker/DateRangePicker';
import Downloads from './components/Downloads/Downloads';
import ExpandableInput from './components/ExpandableInput/ExpandableInput';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './components/ErrorPage/ErrorPage';
import GeneralErrorPage from './components/GeneralErrorPage/GeneralErrorPage';
import Html from './components/Html/Html';
import FeaturedIcon from './components/FeaturedIcon/FeaturedIcon';
import Image from './components/Image';
import Icon from './components/Icon/Icon';
import IconLabel from './components/IconLabel/IconLabel';
import Imports from './components/Imports/Imports';
import InfiniteScroll from './components/InfiniteScroll/InfiniteScroll';
import Input, { FormikInput } from './components/Input/Input';
import InputLabel from './components/Input/InputLabel/InputLabel';
import InputCheckboxes from './components/InputCheckboxes/InputCheckboxes';
import InputGroup from './components/Input/InputGroup/InputGroup';
import Labeled from './components/Labeled/Labeled';
import Link from './components/Link/Link';
import LinkList from './components/LinkList/LinkList';
import List from './components/List/List';
import Logo from './components/Logo/Logo';
import Map from './components/Map/Map';
import Modal from './components/Modal/Modal';
import ModuleHead from './components/ModuleHead/ModuleHead';
import MoreMenu from './components/MoreMenu';
import PageContainer from './components/PageContainer/PageContainer';
import PageTabs from './components/PageTabs/PageTabs';
import Pagination from './components/Pagination/Pagination';
import Paper from './components/Paper/Paper';
import UserCard from './components/UserCard/UserCard';
import Preloader from './components/Preloader';
import ProfileWidget from './components/ProfileWidget/ProfileWidget';
import Radio from './components/Radio/Radio';
import RadioGroup from './components/RadioGroup/RadioGroup';
import Select from './components/Select/Select';
import SelectMenu from './components/SelectMenu/SelectMenu';
import SelectInputGroup from './components/SelectInputGroup/SelectInputGroup';
import SiteSelector from './components/SiteSelector/SiteSelector';
import SelectInput from './components/SelectInput/SelectInput';
import Snackbar from './components/Snackbar/Snackbar';
import Spinner from './components/Spinner/Spinner';
import SparkChart from './components/SparkChart/SparkChart';
import AreaChart from './components/AreaChart/AreaChart';
import StatusIndicator from './components/StatusIndicator/StatusIndicator';
import Popover from './components/Popover';
import Rating from './components/Rating/Rating';
import Switch from './components/Switch/Switch';
import Selector from './components/Selector';
import Tab from './components/TabItem/TabItem';
import TagList from './components/TagList/TagList';
import TagListFormik from './components/TagList/TagListFormik';
import Table from './components/Table/Table';
import Textarea from './components/Textarea/Textarea';
import TextareaGroup, { FormikTextarea } from './components/TextareaGroup/TextareaGroup';
import Timeline from './components/Timeline';
import TimelineSpanner from './components/TimelineSpanner';
import Tooltip from './components/Tooltip/Tooltip';
import Typography from './components/Typography/Typography';
import PrivateRoute from './components/PrivateRoute';
import RequestError from './components/RequestError/RequestError';
import PublicRoute from './components/PublicRoute';
import ScrollToTop from './components/ScrollToTop';
import SvgIcon from './components/SvgIcon/SvgIcon';
import Svg from './components/Svg/Svg';
import UserAvatar from './components/UserAvatar/UserAvatar';
import FileImg from './components/FileImg/FileImg';
import AvatarUploader from './components/AvatarUploader/AvatarUploader';
import FormikAvatarUploader from './components/AvatarUploader/FormikAvatarUploader';
import DefaultAvatar from './components/AvatarUploader/DefaultAvatar/DefaultAvatar';
import LogOnlyErrorBoundary from './components/LogOnlyErrorBoundary/LogOnlyErrorBoundary';
import WindowedSelect from './components/WindowedSelect';
import Wysiwyg, { FormikWysiwyg } from './components/Wysiwyg';

import EmailAddress from './components/EmailAddress/EmailAddress';
import WebAddress from './components/WebAddress/WebAddress';
import PhoneNumber from './components/PhoneNumber/PhoneNumber';

import AsyncErrorBoundary from './components/AsyncErrorBoundary';
import DisplayErrorBoundary from './components/DisplayErrorBoundary';
import withAsyncErrorBoundary from './components/withAsyncErrorBoundary';
import withDisplayErrorBoundary from './components/withDisplayErrorBoundary';
import FusionChart from './components/FusionChart/FusionChart';
import Accordion from './components/Accordion/Accordion';
import BreadCrumb from './components/BreadCrumb/BreadCrumb';
import FAQ from './components/FAQ/FAQ';
import MessageContainer from './components/MessageContainer/MessageContainer';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import PlanAlert from './components/PlanAlert/PlanAlert';
import PlanUpgradeModal from './components/PlanUpgradeModal/PlanUpgradeModal';
import { NotificationsContainer } from './components/NotificationsContainer';

import store from './store';

// permissions
import { PermissionsContext, PermissionsAbility } from './context/PermissionsContext';
import PermissionsProvider from './providers/PermissionsProvider';
import useCan from './hooks/useCan';
import useCanMulti from './hooks/useCanMulti';
import useCannot from './hooks/useCannot';

import 'react-dates/initialize';

import feathers from './services/feathersClient';
import * as utils from './utils';
import * as queries from './hooks/queries';
import * as mixins from './store/mixins';
import * as context from './context';
import * as api from './api';

import QueryParamsProvider from './providers/QueryParamsProviders';
import BulkSelectionProvider, { BulkSelectionContext } from './providers/BulkSelectionProvider';

const { queryClient } = api;

export {
  api,
  queries,
  store,
  feathers,
  queryClient,
  context,
  utils,
  mixins,
  useErrorHandler,
  ActionBar,
  AddRemove,
  AlphabeticFilter,
  Avatar,
  Button,
  Badge,
  Can,
  Card,
  CardAllCommentatorsAvatars,
  CardAvatar,
  CardTitle,
  CardSubTitle,
  CardEditLink,
  CardInfo,
  Checkbox,
  ErrorPage,
  ErrorBoundary,
  Collapse,
  CompanySelect,
  CompanyCheckboxes,
  ConditionalWrapper,
  FormikCheckbox,
  CheckboxGroup,
  ContentContainer,
  DefaultDatePicker,
  DatePicker,
  DateRangePicker,
  Downloads,
  ExpandableInput,
  GeneralErrorPage,
  Html,
  Image,
  IconLabel,
  Icon,
  Imports,
  InfiniteScroll,
  Input,
  InputLabel,
  FormikInput,
  InputCheckboxes,
  InputGroup,
  ModuleHead,
  MoreMenu,
  FeaturedIcon,
  Labeled,
  Link,
  LinkList,
  List,
  Logo,
  LogOnlyErrorBoundary,
  Map,
  Modal,
  NotificationsContainer,
  Pagination,
  PageContainer,
  PageTabs,
  Paper,
  Preloader,
  ProfileWidget,
  Radio,
  RadioGroup,
  Select,
  Selector,
  WindowedSelect,
  SelectMenu,
  SiteSelector,
  SelectInput,
  Snackbar,
  Spinner,
  SparkChart,
  SelectInputGroup,
  AreaChart,
  StatusIndicator,
  Popover,
  Rating,
  Switch,
  Tab,
  TagList,
  TagListFormik,
  Table,
  Textarea,
  FormikTextarea,
  TextareaGroup,
  Timeline,
  TimelineSpanner,
  Tooltip,
  Typography,
  PrivateRoute,
  PublicRoute,
  RequestError,
  ScrollToTop,
  SvgIcon,
  Svg,
  UserAvatar,
  FileImg,
  AvatarUploader,
  FormikAvatarUploader,
  DefaultAvatar,
  EmailAddress,
  WebAddress,
  PhoneNumber,
  UserCard,
  withAsyncErrorBoundary,
  withDisplayErrorBoundary,
  FusionChart,
  Accordion,
  BreadCrumb,
  FAQ,
  MessageContainer,
  PlanAlert,
  PlanUpgradeModal,
  ScrollToTopButton,
  Wysiwyg,
  FormikWysiwyg,
  AsyncErrorBoundary,
  DisplayErrorBoundary,
  PermissionsAbility,
  PermissionsContext,
  PermissionsProvider,
  useCan,
  useCanMulti,
  useCannot,
  QueryParamsProvider,
  BulkSelectionProvider,
  BulkSelectionContext,
};
