import { useAbility } from '@casl/react';
import { subject } from '@casl/ability';
import { PermissionsContext } from '../context/PermissionsContext';

export function useCannot(action, subjectType, object, field) {
  const ability = useAbility(PermissionsContext);

  if (!object) {
    return ability.cannot(action, subjectType, field);
  }

  return ability.cannot(action, subject(subjectType, object), field);
}

export default useCannot;
