import { api } from '@webfx/core-web';
import { useNotifications } from '@webfx/web-hooks';
import defaultUser from '@webfx/permissions-web/src/ui/pages/Users/User/defaultUser';

export default function useUser(userId, queryParams = {}, reactQueryParams = {}) {
  const { toast } = useNotifications();
  const queryClient = api.useQueryClient();

  const { data, ...passThroughQuery } = api.useQuery(
    [
      'users',
      Number(userId),
      {
        ...queryParams,
        query: {
          $join: ['companies', 'companiesUsers', 'products', 'sites', 'emails'],
          ...queryParams.query,
        },
      },
    ],
    {
      enabled: !!userId,
      ...reactQueryParams,
    }
  );

  const mutator = api.useMutation(['users', userId], 'userId', [passThroughQuery.queryKey], {
    onSuccess: (response) => {
      toast.success(
        `${
          response.firstName || response.lastName || response.fullName || response.email
        } has been ${userId ? 'updated' : 'created'}.`
      );
      queryClient.invalidateQueries('users');
    },
  });

  return {
    data: data ?? defaultUser,
    ...passThroughQuery,
    mutator,
  };
}
